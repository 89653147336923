/* eslint-disable react/no-array-index-key */
import React, {
  //   lazy,
  // Suspense,
  Fragment,
} from "react";
import { Redirect, Route } from "react-router-dom";

import jwt_decode from "jwt-decode";
import AllRoutes from "./routes/index.routes";
import Home from "./views/Home";
import Login from "./views/connect/Login";
import Topmenu from "./layouts/topmenu/topmenu";
import Header from "./layouts/header/header";
import Footer from "./layouts/footer/footer";
import InfosFooter from "./layouts/infosfooter/infosfooter";
import Breadcrumb from "./layouts/breadcrumb/breadcrumb";
import Categories from "./views/categories/Categories";
import SimulateurMain from "./views/simulateurs/SimulateurMain";
import DmtgMain from "./views/dmtg/DmtgMain";
import OthersMain from "./views/others/OthersMain";
import Profile from "./views/user/Profile";
import Avis from "./views/informations/avis";
import Fiches from "./views/informations/fiches";
import FichesImmo from "./views/informations/fiches_immo";
import FichesFamille from "./views/informations/fiches_famille";
import FichesGenerales from "./views/informations/fiches_generales";
import Rappels from "./views/informations/rappels";
import Pratiques from "./views/informations/pratiques";
import SchemaFiscalite from "./views/special/SchemaFiscalite";

import ForgotPassword from "./views/ForgotPassword/ForgotPassword";

const routesConfig = [
  {
    exact: true,
    path: "/",
    component: () => <Redirect to="/" />,
  },
  {
    exact: true,
    path: "/login",
    component: () => <Redirect to="/login" />,
  },
  {
    exact: true,
    path: "/categorie",
    component: () => <Redirect to="/" />,
  },
  {
    exact: true,
    path: "/categorie/:url_name/simulateur",
    component: () => <Redirect to="/categorie/:url_name" />,
  },
  {
    exact: true,
    path: "/categorie/:url_name#:id",
    component: () => <Redirect to="/categorie/:url_name" />,
  },
  {
    exact: true,
    path: "/simulateur",
    component: () => <Redirect to="/" />,
  },
  {
    exact: true,
    path: "/moncompte",
    component: () => <Redirect to="/moncompte" />,
  },
  {
    exact: true,
    path: "/fiches/fiches_immo",
    component: () => <Redirect to="/fiches/fiches_immo" />,
  },
  {
    exact: true,
    path: "/fiches/fiches_famille",
    component: () => <Redirect to="/fiches/fiches_famille" />,
  },
  {
    exact: true,
    path: "/fiches/fiches_generales",
    component: () => <Redirect to="/fiches/fiches_generales" />,
  },
  {
    exact: true,
    path: "/SchemaFiscalite",
    component: () => <Redirect to="/SchemaFiscalite" />,
  },
  // {
  //     exact: true,
  //     path: '/404',
  //     component: lazy(() => import('src/views/pages/Error404View'))
  // },
  AllRoutes,
  {
    path: "/",
    routes: [
      {
        exact: true,
        path: "/",
        layout: Topmenu,
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () =>
          checkJWT() ? <Redirect to="/" /> : <Redirect to="/login" />,
      },
      {
        exact: true,
        path: "/login",
        layout: Header,
        component: () =>
          checkJWT() ? <Redirect to="/" /> : <Redirect to="/login" />,
      },
      {
        exact: true,
        path: "/moncompte",
        layout: Topmenu,
        layoutHeader: Header,
        component: () => (checkJWT() ? Profile : <Redirect to="/moncompte" />),
      },
      {
        exact: true,
        path: "/categorie/:url_name",
        layout: Topmenu,
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (checkJWT() ? Categories : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/categorie/:url_name/simulateur/:simulateur",
        layout: Topmenu,
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () =>
          checkJWT() ? SimulateurMain : <Redirect to="/login" />,
      },
      {
        exact: true,
        path: "/categorie/:url_name/dmtg/:simulateur",
        layout: Topmenu,
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (checkJWT() ? DmtgMain : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/categorie/:url_name/others/:simulateur",
        layout: Topmenu,
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (checkJWT() ? OthersMain : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/avis",
        layout: Topmenu,
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (checkJWT() ? Avis : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/fiches",
        layout: Topmenu,
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (checkJWT() ? Fiches : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/pratiques",
        layout: Topmenu,
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (checkJWT() ? Fiches : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/fiches/fiches_immo",
        layout: Topmenu,
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (checkJWT() ? FichesImmo : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/fiches/fiches_famille",
        layout: Topmenu,
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () =>
          checkJWT() ? FichesFamille : <Redirect to="/login" />,
      },
      {
        exact: true,
        path: "/fiches/fiches_generales",
        layout: Topmenu,
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () =>
          checkJWT() ? FichesGenerales : <Redirect to="/login" />,
      },
      {
        exact: true,
        path: "/rappels",
        layout: Topmenu,
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (checkJWT() ? Rappels : <Redirect to="/login" />),
      },
      {
        exact: true,
        path: "/SchemaFiscalite",
        layout: Topmenu,
        layoutHeader: Header,
        layoutBreadCrumb: Breadcrumb,
        component: () => (checkJWT() ? SchemaFiscalite : <Redirect to="/login" />),
      },
      // {
      //     component: () => <Redirect to="/404"/>
      // }
    ],
  },
  {
    path: "*",
    // layout: Topmenu,
    routes: [
      {
        exact: true,
        path: "/",
        component: Home,
        layoutInfoFooter: InfosFooter,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/login",
        component: Login,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/categorie/:url_name",
        component: Categories,
        layoutInfoFooter: InfosFooter,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/moncompte",
        component: Profile,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/categorie/:url_name/simulateur/:simulateur",
        component: SimulateurMain,
        layoutInfoFooter: InfosFooter,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/categorie/:url_name/dmtg/:simulateur",
        component: DmtgMain,
        layoutInfoFooter: InfosFooter,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/categorie/:url_name/others/:simulateur",
        component: OthersMain,
        layoutInfoFooter: InfosFooter,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/avis",
        component: Avis,
        layoutInfoFooter: InfosFooter,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/fiches",
        component: Fiches,
      },
      {
        exact: true,
        path: "/pratiques",
        component: Pratiques,
        layoutInfoFooter: InfosFooter,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/fiches/fiches_immo",
        component: FichesImmo,
      },
      {
        exact: true,
        layout: Topmenu,
        layoutHeader: Header,
        path: "/passwordForgot",
        component: ForgotPassword,
      },
      {
        exact: true,
        path: "/fiches/fiches_famille",
        component: FichesFamille,
      },
      {
        exact: true,
        path: "/fiches/fiches_generales",
        component: FichesGenerales,
      },

      {
        exact: true,
        path: "/rappels",
        component: Rappels,
        layoutInfoFooter: InfosFooter,
        layoutFooter: Footer,
      },
      {
        exact: true,
        path: "/SchemaFiscalite",
        component: SchemaFiscalite,
        layoutInfoFooter: InfosFooter,
        layoutFooter: Footer,
      },

      // {
      //     component: () => <Redirect to="/404" />
      // }
    ],
  },
];

function checkJWT() {
  if (localStorage.getItem("user")) {
    const userInStorage = JSON.parse(localStorage.getItem("user")).jwt;
    if (userInStorage != undefined) {
      let decodedToken = jwt_decode(userInStorage);
      let currentDate = new Date();
      // JWT exp is in seconds
      if (decodedToken.exp * 1000 - 86400 < currentDate.getTime()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}

const renderRoutes = (routes) =>
  routes ? (
    //<Suspense
    // fallback={<LoadingScreen/>}
    //>
    <Route>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const LayoutFooter = route.layoutFooter || Fragment;
        const LayoutHeader = route.layoutHeader || Fragment;
        const LayoutInfoFooter = route.layoutInfoFooter || Fragment;
        const LayoutBreadCrumb = route.layoutBreadCrumb || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <>
                <Layout />
                <LayoutHeader />
                <LayoutBreadCrumb />
                <>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </>

                <LayoutInfoFooter />
                <LayoutFooter />
              </>
            )}
          />
        );
      })}
    </Route>
  ) : //</Suspense>
  null;

function RenderRoutes() {
  return renderRoutes(routesConfig);
}

export default RenderRoutes;

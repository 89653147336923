import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import ButtonYesno from "../../../components/buttons/button.yesno";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import { act } from "react-dom/test-utils";
import { ToolTipPerso } from "../../../components/selects/tooltip_perso";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// simulateur Id 20

function Simulateur_ESIM(props, simulateur, isParent, departements) {
  const dispatch = useDispatch();

  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [departement, setDepartement] = useState(0);
  const [urbanisme, setUrbanisme] = useState(0);
  const [montantUrbanisme, setMontantUrbanisme] = useState(0);
  const [montantLot, setMontantLot] = useState(0);
  const [montantMeuble, setMontantMeuble] = useState(0);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
      };

      if (setter === setDepartement) {
        Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
        Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
        Object.assign(object["inputs"], { montant_lot: parseInt(montantLot) });
        Object.assign(object["inputs"], { montant_meuble: parseInt(montantMeuble) });
        object.departementid = parseInt(value);
      }

      if (setter === setUrbanisme) {
        Object.assign(object["inputs"], { urbanisme: parseInt(value) });
        Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
        Object.assign(object["inputs"], { montant_lot: parseInt(montantLot) });
        Object.assign(object["inputs"], { montant_meuble: parseInt(montantMeuble) });
        object.departementid = parseInt(departement);
      }

      if (setter === setMontantUrbanisme) {
        Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
        Object.assign(object["inputs"], { montant_urbanisme: parseInt(value) });
        Object.assign(object["inputs"], { montant_lot: parseInt(montantLot) });
        Object.assign(object["inputs"], { montant_meuble: parseInt(montantMeuble) });
        object.departementid = parseInt(departement);
      }

      if (setter === setMontantLot) {
        Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
        Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
        Object.assign(object["inputs"], { montant_lot: parseInt(value) });
        Object.assign(object["inputs"], { montant_meuble: parseInt(montantMeuble) });
        object.departementid = parseInt(departement);
      }

      if (setter === setMontantMeuble) {
        Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
        Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
        Object.assign(object["inputs"], { montant_lot: parseInt(montantLot) });
        Object.assign(object["inputs"], { montant_meuble: parseInt(value) });
        object.departementid = parseInt(departement);
      }

      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>Acte tarifé au titre du Tableau 5, n° 96 (Art A444-117)</p>

        {props?.isParent === true && props.departements !== "" ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Quel est le montant du lot 1 ou du lot 2 ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setMontantLot}
          />
        </div>

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Quel est le montant des meubles du lot 1 ou du lot 2 ?</div>

          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setMontantMeuble}
          />
        </div>

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Y a-t-il un urbanisme demandé à un tiers, ou d'autres débours à prévoir ?
            <ToolTipPerso
              title={
                "Les débours sur les ventes comprennent une somme de 150 euros représentant le coût moyen d'obtention d'un certificat d'urbanisme complet. Pour retirer ce coût, répondre 'non' à la question 'urbanisme demandé à un tiers'."
              }
            />
          </div>
          <div>
            <ButtonYesno handleChange={handleChange} setter={setUrbanisme} fieldUndefined={true} defaultValue={2} />
          </div>
        </div>

        {parseInt(urbanisme) === 1 ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>Montant de l'urbanisme :</div>
            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer le montant"}
              setter={setMontantUrbanisme}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Simulateur_ESIM;

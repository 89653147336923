import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import ButtonTextCautio from "../../../components/buttons/button.text.cautio";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Cautionnement avec hypothèque conventionnelle pour prêts professionnels hypothécaires ou hypothèque conventionnelle
// id simu 97

function Simulateur_A2_19(props, simulateur, isParent, departements) {
  const dispatch = useDispatch();

  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const currentIdSimu = 97;
  const [montant, setMontant] = useState(0);
  const [departement, setDepartement] = useState(0);
  const [cautionnement, setCautionnement] = useState(0);

  const handleChange = async (setter, value) => {
    setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  function setInRedux(setter, value) {
    let object = {
      simulateurid: currentIdSimu,
      departementid: 0,
      inputs: {},
    };

    if (setter === setMontant) {
      Object.assign(object["inputs"], { montant_cautionnement: parseInt(value) });
      Object.assign(object["inputs"], { cautionnement_realise: parseInt(cautionnement) });
      object.departementid = parseInt(departement);
    }

    if (setter === setCautionnement) {
      Object.assign(object["inputs"], { montant_cautionnement: parseInt(montant) });
      Object.assign(object["inputs"], { cautionnement_realise: parseInt(value) });
      object.departementid = parseInt(departement);
    }

    if (setter === setDepartement) {
      Object.assign(object["inputs"], { montant_cautionnement: parseInt(montant) });
      Object.assign(object["inputs"], { cautionnement_realise: parseInt(cautionnement) });
      object.departementid = parseInt(value);
    }

    dispatch(setSimulateurObject(object));

    //props.contentDep(object);
    if (props.isParent) {
      dispatch(setSimulateurObject(object));
    } else {
      object.departementid = parseInt(props.departementId);
      props.dependances(object);
    }
  }

  return (
    <>
      {props?.isParent === true && props.departements !== "" ? (
        <div style={styles.container}>
          <div style={styles.card}>
            <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

            <TextfieldRemarques handleChange={handleChangeRemarque} />

            <p style={styles.cardSubTitle}>Acte tarifé au titre du Tableau 5, n° 111 (Art A444-127)</p>

            {props?.isParent === true && props.departements !== "" ? (
              <div style={styles.cardRow}>
                <div style={styles.cardElemVerticalyCenter}>Département :</div>

                <SelectDepartement
                  items={props.departements}
                  nameField={"name"}
                  defaultValue={currUserDep}
                  optionValue={"id"}
                  handleChange={handleChange}
                  setter={setDepartement}
                />
              </div>
            ) : (
              <></>
            )}

            <div style={styles.cardRow}>
              <div style={styles.cardElemVerticalyCenter}>L'acte de cautionnement est réalisé :</div>
              <ButtonTextCautio handleChange={handleChange} setter={setCautionnement} defaultValue={0} />
            </div>

            <div style={styles.cardRow}>
              <div style={styles.cardElemVerticalyCenter}>Indiquer le montant du cautionnement :</div>
              <TextfieldNumber
                handleChange={handleChange}
                type={"number"}
                placeholder={"Indiquer le montant"}
                setter={setMontant}
              />
            </div>
          </div>
        </div>
      ) : (
        <div style={styles.cardRowChild}>
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>L'acte de cautionnement est réalisé :</div>
            <ButtonTextCautio handleChange={handleChange} setter={setCautionnement} defaultValue={0} />
          </div>

          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>Indiquer le montant du cautionnement :</div>
            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer le montant"}
              setter={setMontant}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Simulateur_A2_19;

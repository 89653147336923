import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// simulateur Id 22

function Simulateur_A1_24(props, simulateur, isParent, departements) {
  const dispatch = useDispatch();

  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [departement, setDepartement] = useState(0);
  const [montant_licitee, setMontant] = useState(0);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
      };

      if (setter === setDepartement) {
        Object.assign(object["inputs"], { montant_licitee: parseInt(montant_licitee) });
        object.departementid = parseInt(value);
      }

      if (setter === setMontant) {
        Object.assign(object["inputs"], { montant_licitee: parseInt(value) });
        object.departementid = parseInt(departement);
      }

      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>
          Acte tarifé au titre du Tableau 5, n° 48 (Art A444-87).
          <br /> Sauf dispositions contraires en date du 1er janvier 2021, l’article 750 II du CGI reste inchangé et n’a
          pas été modifié suite à la réforme du droit de partage - Art 108 V issue de la Loi de finances 2020
          N°2019-1474.
        </p>

        {props?.isParent === true && props.departements !== "" ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Quel est le montant de la part licitée ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setMontant}
          />
        </div>
      </div>
    </div>
  );
}

export default Simulateur_A1_24;

import React, {createRef, useEffect, useState} from 'react';
import Colors from "../../useclass/colors";

function Rappels(props) {
    const notes = createRef()
    const abattements = createRef()
    const reductions = createRef()
    const donations = createRef()
    const possession = createRef()
    const sincerite = createRef()

    const styles = {
        container: {
            padding: 0,
            margin: 0,
            width: "100vw",
            minHeight: "auto",
            textAlign: "justify",
        },
        card: {
            backgroundColor: Colors.white,
            boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
            borderRadius: 5,
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 50,
            paddingBottom: 50,
            minHeight: "30vh",
            paddingLeft: 50,
            paddingRight: 50,
            textAlign: "justify",
        },
        listElem: {
            lineHeight: 1.8,
            cursor: "pointer",
            textDecoration: "underline",
        },
        cardTitle: {
            fontWeight: "normal",
            color: "#5E91B6",
            textAlign: "center",
            lineHeight: "1.4em",
            paddingTop: 20,
            marginTop: 5,
            marginBottom: 60,
        },
        cardSubTitle: {
            fontSize: 20,
            marginLeft: 30,
            textAlign: "justify",
            color: "#5E91B6",
            textDecoration: "underline",
            marginTop: 50,
            marginBottom: 15,
        },
        cardRow: {
            width: "90%",
            paddingTop: 3,
            paddingBottom: 3,
            textAlign: "justify",
        },

        subTitle: {
            fontSize: 17,
            fontWeight: "bold",
            marginTop: 30,
            marginBottom: 15,
        }
    };

    let newDate = new Date()
    let year = newDate.getFullYear();

    let handleScrollTo = (elRef) => {
        // Incase the ref supplied isn't ref.current
        //console.log("elRef")
        //console.log(elRef)
        const el = elRef.current ? elRef.current : elRef

        //console.log("el")
        //console.log(el)

        // Scroll the element into view
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h1 style={styles.cardTitle}>
                    Rappels successions & donations
                </h1>
                <div>
                    <div style={styles.cardSubTitle}>
                        Tarif des droits de mutation par décès, à titre gratuit à jour au 1er
                        janvier {year}
                    </div>
                    <div>
                        <i>Les renseignements indiqués ont été regroupés et analysés avec le plus grand soin mais ne
                            sont donnés qu'à titre indicatif. Par ailleurs Il ne se prétendent pas être exhaustifs mais
                            visent à l'essentiel. En cas de doute, merci de vous reporter aux textes officiels dont ils
                            sont pour la plupart issus.</i>
                    </div>
                    <ul>
                        <li style={styles.listElem} onClick={() => {handleScrollTo(notes)}}>Notes pratiques diverses
                        </li>
                        <li style={styles.listElem} onClick={() => {handleScrollTo(abattements)}}>Abattements</li>
                        <li style={styles.listElem} onClick={() => {handleScrollTo(reductions)}}>Réductions de
                            droits
                        </li>
                        <li style={styles.listElem} onClick={() => {handleScrollTo(donations)}}>Donations
                            antérieures
                        </li>
                        <li style={styles.listElem} onClick={() => {handleScrollTo(possession)}}>Envoi en possession
                            -
                            renonciation et acceptation de la succession
                        </li>
                        <li style={styles.listElem} onClick={() => {handleScrollTo(sincerite)}}>Affirmation de
                            sincérité
                        </li>
                    </ul>
                </div>
                <div id="notes" ref = {notes}>
                    <div style={styles.cardSubTitle}><strong>Notes pratiques diverses</strong></div>
                    <div>
                        <div style={styles.subTitle}>Obligations et mode de souscription de la déclaration de succession</div>
                        La déclaration de succession doit être déposée à la recette des impôts dont dépend le domicile
                        du défunt s’il vivait en France ou à celle de Noisy le Grand (93465) -10 rue du Centre TSA 10010 -
                        pour les non-résidents. Déclaration à établir si l’actif brut de la successions est supérieur ou égal
                        à 3 000 € pour l’ensemble des successions sauf pour les successions en ligne directe, entre époux ou
                        entre partenaires d’un Pacs (voir ci-dessous). Pour les successions en ligne directe ouvertes
                        depuis le 1er janvier 2006 il n’y a pas lieu d’établir une déclaration si l’actif brut est inférieur à
                        50 000 €. (Art. 800 du C.G.I. ). Pour les successions, entre époux ou entre partenaires d’un Pacs,
                        ouvertes depuis le 22 août 2007, il n‘y a pas lieu d’établir une déclaration si l’actif brut est
                        inférieur à 50 000 € à condition que l’interessé n’ait pas bénéficié antérieurement de la part
                        du défunt d’une donation ou d’un don manuel non enregistré ou non déclaré (Art. 800 du C.G.I.
                        modifié par la loi “TEPA“). Les déclarations doivent être déposées en double exemplaire sauf si l’actif
                        brut est inférieur ou égal à 15 000 € ( Art. 800 II du C.G.I. ).</div>
                    <div>
                        <div style={styles.subTitle}>Délais pour souscrire</div>
                        Le délai pour déposer la déclaration de succession est de six mois lorsque le décès a lieu en
                        France
                        Métropolitaine ; il est de un an dans les autres cas. L’article 11 de la Loi de Finances
                        N°2013-1278
                        du 30 décembre 2013 pour 2014 incite à la reconstitution des immeubles non titrés et dont la
                        propriété est incertaine. <u>Un délai de 24 mois est désormais accordé à compter du
                            décès.</u> Cette
                        disposition s’étend à l’ensemble du territoire français y compris la Corse. Les établissements
                        pourvus d'une autorité tutélaire bénéficient d'un délai qui court à compter de l'acceptation par
                        l'autorité tutélaire avec un maximum de deux ans à compter du décès. Lorsqu'un ou plusieurs
                        héritiers sont inconnus au jour du décès, le délai ci-dessus ne commence à courir qu'à compter
                        du
                        jour où la révélation de la succession leur est faite (Art. 3637 du Dictionnaire de
                        l'Enregistrement). Dans une succession vacante ou en déshérence qui a été appréhendée par
                        l'Etat,
                        les héritiers, qui se manifestent par la suite et auxquels les biens ont été restitués,
                        disposent
                        d'un délai de six mois à compter de la décision judiciaire ou administrative ayant ordonné la
                        remise
                        de la succession entre leurs mains pour souscrire la déclaration de succession (Art. 3640 du
                        Dictionnaire de l'Enregistrement).</div>
                    <div>
                        <div style={styles.subTitle}>Interêts de retard - Majorations</div>
                        0,40% par mois du montant des droits non versés dans le délai imparti.(application depuis le
                        01/01/2006). Si le dépôt tardif de la
                        déclaration n’est pas accompagné du montant des droits il est perçu en outre 5 % des sommes dont
                        le
                        montant a été différé. Par ailleurs pour toute déclaration déposée hors délai après un an et
                        plus
                        après le décès, majoration des droits de 10% pouvant aller de 40% à 80% après mise en demeure
                        par
                        l’Administration (Art. 1728 nouveau du C.G.I.).</div>
                    <div><br />
                        <b>Prescription</b><br /><br />
                        Il y a prescription de l’action de l’Administration :
                        <ul>
                            <li style={styles.cardRow}>6 ans à compter du jour du décès pour les successions non
                                déclarées et pour omissions,
                                erreurs, dettes rejetées, etc... (Art. L186 du Livre des Procédures Fiscales)
                            </li>
                            <li style={styles.cardRow}>dernier jour de la troisième année suivant celle du dépôt de la
                                déclaration pour les
                                droits
                                afférents aux biens énoncés dans la déclarations (Art. L180 du Livre des Procédures
                                Fiscales). Le simple dépôt de la déclaration de succession au service des impôts ne
                                suffit
                                pas pour faire courir le délai de prescription abrégé. Il est préférable de faire
                                enregistrer le plus rapidement possible la déclaration pour raccourcir ce délai.
                            </li>
                            <li style={styles.cardRow}>en matière de don manuel, en cas d’absence de sa révélation
                                spontanée, le rappel fiscal
                                est
                                de 15 ans.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div style={styles.subTitle}>Passif déductible</div>
                        Parmi les différents frais déductibles, sont notamment déductibles, les frais de dernière
                        maladie, sans limitation, sur
                        justificatifs. Il en est de même pour les frais de reconstitution de droit sur des immeubles
                        dont la propriété est incertaine - Art 11 LF 2013-1278 du 30 décembre 2013. Les frais
                        d’inhumation sont déductibles sur simples justificatifs à concurrence de 1 500 € pour les
                        successions ouvertes depuis le 01/01/2003 ( Art. 775 du C.G.I. et B.O.I. 7G-2-03 N°82 du 6 mai
                        2003).
                    </div>
                    <div>
                        <div style={styles.subTitle}>Paiement fractionné</div>
                        Sur demande de tout légataire ou de tout cohéritier, le montant des droits de mutation peut être
                        acquitté en
                        plusieurs versements égaux, étalés sur une durée maximale de 5 ans (porté à 10 ans en ligne
                        directe), avec perception d’un intérêt et en fournissant des garanties d’une valeur au moins
                        double de ces droits (généralement avec prise d’hypothèque).
                    </div>
                    <div>
                        <div style={styles.subTitle}>Paiement différé</div>
                        S’applique aux droits de mutation dus par une personne ne recueillant que la nue-propriété d’un
                        bien ; le
                        paiement des droits est alors différé jusqu’à l’expiration du délai de six mois qui suit le
                        décès de l’usufruitier. Deux possibilités :
                        <ul>
                            <li style={styles.cardRow}>les droits sont calculés sur la valeur de la nue-propriété : le
                                bénéficiaire de cette
                                disposition paye un intérêt annuel jusqu’au jour du paiement effectif des droits dus ;
                            </li>
                            <li style={styles.cardRow}>les droits sont calculés sur la valeur de la toute propriété :
                                aucun intérêt n’est dû.
                                L’Administration prend une garantie hypothécaire sur les biens représentant au moins le
                                double de la valeur des droits différés.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div style={styles.subTitle}>Paiement différé et fractionné</div>
                        Les droits de succession exigibles sur la transmission d’une entreprise ayant été exploitée par
                        le défunt
                        peuvent faire l’objet sous certaines conditions du paiement différé de 5 ans puis à l’expiration
                        de ce délai d’un paiement fractionné sur 10 ans. (Décret N°85-356 du 23/03/85).
                    </div>
                    <div>
                        <b>Meubles meublants</b><br /><br />
                        Leur valeur fiscale est déterminée dans l’ordre suivant :
                        <ul>
                            <li style={styles.cardRow}>le produit net de leur vente aux enchères publiques, si elle
                                intervient dans les 2 ans du
                                décès ;
                            </li>
                            <li style={styles.cardRow}>à défaut l’estimation portée dans un inventaire clôturé et dressé
                                dans les 5 ans du décès
                                ;
                            </li>
                            <li style={styles.cardRow}>sinon, forfait de 5 % de l’ensemble des autres biens de la
                                succession, calculé comme suit
                                :
                            </li>
                            <ul>
                                <li style={styles.cardRow}>déterminer l’actif brut de communauté duquel on déduit
                                    l’excédent de récompense dû par
                                    la communauté.
                                </li>
                                <li style={styles.cardRow}>à chaque époux, ou auquel on ajoute l’excédent de
                                    récompense
                                    dû par chaque époux à la
                                    communauté,
                                </li>
                                <li style={styles.cardRow}>diviser par deux (sans déduire le passif),</li>
                                <li style={styles.cardRow}>à la moitié revenant à la succession déduire les
                                    récompenses
                                    dues par le défunt ou
                                    ajouter les récompenses dues par la communauté au défunt,
                                </li>
                                <li style={styles.cardRow}>déduire la moitié du mobilier de communauté (s’il en
                                    existe),
                                </li>
                                <li style={styles.cardRow}>ajouter les propres du défunt,</li>
                                <li style={styles.cardRow}>prendre 5 % du résultat.</li>
                            </ul>
                        </ul>
                        Le forfait ne couvre pas :
                        <ul>
                            <li style={styles.cardRow}>les collections d’objets d’art, qui font l’objet d’une
                                déclaration détaillée ;
                            </li>
                            <li style={styles.cardRow}>les bijoux, pierreries, objets d’art ou de collection, dont la
                                valeur ne peut être
                                inférieure à celle figurant dans le contrat d’assurance ;
                            </li>
                            <li style={styles.cardRow}>les meubles corporels.</li>
                            <li style={styles.cardRow}>Le forfait ne se calcule pas sur les rapports de dons en
                                avancement d’hoirie ;
                            </li>
                            <li style={styles.cardRow}>les biens exonérés de droits.</li>
                        </ul>
                    </div>
                    <div>
                        <div style={styles.subTitle}>Adoption simple</div>
                        Il n’est pas tenu compte du lien de parenté résultant de l’adoption, en ce qui concerne la
                        perception des droits de mutation à
                        titre gratuit et par décès. L’article 786 du C.G.I. énumère les exceptions à cette règle, au
                        nombre desquelles l’adoption en faveur d’adoptés qui, soit dans leur minorité et pendant 5
                        ans
                        au moins, soit dans leur minorité et leur majorité et pendant 10 ans au moins, auront reçu
                        de
                        l’adoptant des secours et des soins non interrompus.
                    </div>
                    <div>
                        <div style={styles.subTitle}>Fonds national de solidarité</div>
                        Lorsque l’actif net de succession atteint 39 000 €, les arrérages versés au titre de
                        l’allocation du
                        F.N.S. sont récupérés sur l’actif de succession, mais seulement dans la limite de la partie
                        de
                        succession dépassant ce chiffre.
                    </div>
                </div>
                <div id="abattements" ref={abattements}>
                    <div style={styles.cardSubTitle}><strong>Abattements</strong></div>

                    <div>
                        <div style={styles.subTitle}>ABATTEMENT GENERAL</div>
                        L’abattement applicable à défaut d’un autre abattement sur la part successorale reçue est de 1
                        594 € (Art. 788 IV du
                        C.G.I.) modifié par la Loi de Finances pour 2011.
                    </div>
                    <div>
                        <div style={styles.subTitle}>ABATTEMENT PARTICULIERS</div>
                        <strong><em>Entre époux</em></strong><br />
                        L’abattement applicable sur la part reçue par le conjoint du donateur est de <strong>80 724
                            € </strong>(Art. 790 E du C.G.I.) Loi de
                        Finances pour 2011.
                    </div><br />
                    <div>
                        <strong><em>En ligne directe</em></strong><br />
                        L’abattement applicable en cas de donation ou de succession sur la part de
                        chacun des ascendants et sur la part de chacun des enfants vivants ou représentés par suite de
                        prédécès ou de renonciation est de <strong>100 000 € </strong>(Art. 779 du C.G.I.) modifié par
                        la 2e Loi de Finances 2012.
                    </div><br />
                    <div>
                        <strong><em>Entre frères et soeurs</em></strong><br />
                        L’abattement applicable en cas de donation ou de succession, sur la part de chacun des frères ou
                        soeurs
                        vivants ou représentés par suite de prédécès ou de renonciation, est de <strong>15 932
                            € </strong>(Art. 779 du C.G.I.) modifié par la Loi de finances pour 2011. Il y a
                        Exonération* en cas de succession seulement lorsque les dispositions de l’Art 796-0 du CGI
                        s’appliquent, à savoir être agé de plus de 50 ans ou infirme et avoir été constamment domicilié
                        avec le défunt pendant les 5 ans précédant le décès.
                    </div><br />
                    <div>
                        <strong><em>Au profit des petits-enfants</em></strong><br />
                        L’abattement prévu en faveur de chacun des petits-enfants du donateur est de <strong>31 865
                            € </strong>(Art. 790 B du C.G.I.) modifié par
                        la Loi de Finances pour 201.
                    </div><br />
                    <div>
                        <em><strong>Au profit des arrières petits-enfants </strong></em><br />
                        L’abattement prévu en faveur de chacun des arrières petits-enfants
                        du donateur est de <strong>5 310 € </strong>(Art. 790 D du C.G.I.) modifié par la Loi de
                        Finances pour 2011.
                    </div><br />
                    <div>
                        <strong> </strong><em><strong>Au profit des neveux et nièces</strong></em><br />
                        L’abattement effectué sur la part de chacun des neveux et nièces
                        est de <strong>7 967 € </strong>(applicable aux donations et aux successions (Art. 779 du
                        C.G.I.) modifié par la Loi de Finances pour 2011. Les neveux et nièces venant par représentation
                        se partagent l’abattement dont leur parent auraient profité (voir paragraphe ci-dessus). Les
                        abattements ne sont pas cumulables. En cas de souche unique les neveux sont réputés hériter de
                        leur propre chef. <strong>Cette mesure est rétroactive au 1er janvier
                            2007. </strong>(BODGI 7G-7-09).
                    </div><br />
                    <div>
                        <em><strong>Au profit des infirmes</strong></em><br />
                        L’abattements applicable sur la part de tout héritier,
                        légataire ou donataire, incapable de travailler dans des conditions normales de rentabilité, en
                        raison d’une infirmité physique ou mentale, congénitale ou acquise est de <strong>159 325
                            € </strong>(Art. 779 du C.G.I. ) modifié par la Loi de Finances pour 2011. Cumulable avec
                        les autres abattements sauf celui de 1 594 €. Les donations de moins de 15 ans doivent être
                        rappelées.
                    </div><br />
                    <div>
                        <em><strong>Au profit des personnes ayant signé un PACS </strong></em><br />
                        L'abattement prévu en faveur des transmissions à titre
                        gratuit entre vifs réalisées entre partenaires liés par un pacte civil de solidarité est
                        de <strong>80 724 € </strong>(Art. 790 F du C.G.I.) modifié par la Loi de Finances pour 2011.
                    </div>
                </div>
                <div id="reductions" ref={reductions}>
                    <div style={styles.cardSubTitle}>Réductions de droits</div>
                    <div>
                        Après de multiples changements,
                        opérés par les lois de finances successives, la loi de finances rectificative pour 2011
                        applicable à
                        compter du 31/07/2011 limite à 50% la réduction de droits de donation pour un donateur âgé de
                        moins
                        de 70 ans, mais uniquement s’il s’agit d’une donation en pleine propriété de parts ou actions de
                        société ou d’entreprise individuelle répondant aux critères des « Pactes Dutreil » : ENR-X 42120
                        s.
                        L’article 32 de la Loi N°2016-1917 du 29 décembre 2016 de finances pour 2017 a supprimé la
                        réduction
                        de droits pour charge de famille codifié sous l’article 780 du Code Général des Impôts, pour les
                        successions ouvertes et les donations effectuées à compter du er janvier 2017. Pour les
                        successions
                        ouvertes et les donations effectuées à compter du 1er janvier 2017, la réduction de droit de
                        mutation à titre gratuit dont bénéficie l’héritier, le donataire ou le légataire qui a 3 enfants
                        ou
                        plus, vivants ou représentés au jour de la donation ou au moment de l’ouverture de ses droits à
                        la
                        succession, est donc supprimée. <br />
                        Lien BOFIP : <a
                            href="http://bofip.impots.gouv.fr/bofip/1766-PGP.html?identifiant=BOI-ENR-DMTG-10-50-40-20170213"
                            target="_blank">http://bofip.impots.gouv.fr/bofip/1766-PGP.html?identifiant=BOI-ENR-DMTG-10-50-40-20170213</a>
                    </div>
                    <div>
                        <div style={styles.subTitle}>Cas particulier :</div>
                        La Loi visant à favoriser l’assainissement cadastral et la résorption du désordre de propriété
                        impacte les régimes
                        d’exonération des droits dont bénéficient les transmissions d’immeubles.<br />
                        La première transmission d’immeubles titrés, postérieure à <b>la reconstitution des titres de
                            propriété</b> y afférents, est exonérée de droits de succession ou de donation à concurrence
                        de 30% de la valeur des biens transmis. Cette disposition s’applique à l’ensemble du territoire
                        national y compris la Corse. Ce taux de 30% est porté à 50% et la disposition est prorogée de 10
                        ans (Loi 2017-285 art 3, CGI Art 793,2.8°).<br />
                        L’exonération de 50% s’applique dans le cadre des donations réalisées et des successions
                        ouvertes à compter du 8 mars 2017 aux biens dont
                        les titres de propriété auront été constatés par un acte régulièrement transcrit ou publié
                        entre le 1er octobre 2014 et le 31 décembre 2027. Ce dispositif prendra fin pour les actes
                        transcrits ou publiés à compter du 1er janvier 2028 et non à compter du 1er janvier
                        2018.<br />
                        Pour les immeubles et droits immobiliers situés en Corse, et pour lesquels aucun
                        titre de propriété n’a été établi, ces exonérations ne s’appliquent que si les
                        attestations notariées sont publiées dans les 24 mois du décès (CGI Art 1135bis,
                        II).<br />
                        <b>La loi exonère du droit de 2.5% les actes de partage</b> de succession et
                        les licitations de biens héréditaires à la hauteur de la valeur des biens
                        immobiliers situés <b>en Corse</b>. Cette exonération s’applique aux actes passés du
                        1er janvier 2017 au 31 décembre 2027.
                    </div>
                </div>
                <div id="donations" ref={donations}>
                    <div style={styles.cardSubTitle}>Donations antérieures</div>
                    <div>
                        Les parties sont tenues de
                        rapporter à la déclaration de succession les donations consenties par le défunt et enregistrées
                        depuis moins de 15 ans (2e Loi de Finance Rectificative – 2012-958 du 16 août 2012) au moment du
                        décès, de même que les dons manuels révélés dans la même période.
                    </div>
                    <div>
                        En revanche, il n’y a pas lieu de rapporter les donations de sommes d’argent consenties en
                        pleine
                        propriété, dans le cadre de la loi du 9 août 2004 pour le soutien à la consommation et à
                        l’investissement (dite “mesure Sarkozy“) prolongée par l’instruction 7G-4-05 n°39 du 28 février
                        2005
                        (jusqu’à 30 000 € : donations effectuées entre le 01/06/2004 et le 31/12/2005 au profit
                        d’enfants,
                        petits-enfants ou neveux et nièces).
                    </div>
                    <div>
                        Sont également dispensés de rapport les dons de sommes d’argent consentis au profit des
                        descendants ou à défaut de neveux et
                        nièces du 1er janvier 2006 au 31/12/2010 (article 790 A bis modifié du C.G.I.). Par ailleurs
                        sont
                        dispensés de rapport les dons de sommes d’argent en pleine propriété par des personnes de moins
                        de
                        65 ans au profit d’un bénéficiaire majeur qu’il soit son enfant, son petit-enfant ou son arrière
                        petit enfant ou a défaut de descendant, un neveu ou une nièce ou les arrières petits-neveux ou
                        arrières-petites nièces venant par représentation, dans la limite de 30.390 € sans limitation
                        dans
                        le temps. Dispositions résultant de la loi “TEPA“ précitée (art 790 G nouveau du C.G.I. modifié
                        par
                        la Loi de finances pour 2008 et la Loi de Finances rectificative pour 2007).
                    </div>
                    <div>
                        Dispositions résultant de la loi “TEPA“ précitée (art 790 G nouveau du C.G.I.
                        modifié par la Loi de finances pour 2008 et la Loi de Finances rectificative pour 2007).
                    </div>
                </div>
                <div id="possession" ref={possession}>
                    <div
                        style={styles.cardSubTitle}>Envoi en possession - renonciation et acceptation de la succession</div>
                    <div style={styles.subTitle}>L’envoi en possession</div>
                    <div>
                        L’envoi en possession est une procédure qui obligeait le légataire universel désigné par
                        testament olographe ou mystique à faire contrôler par le président
                        du Tribunal de grande instance la validité apparente du testament, dès lors qu’il n’y a pas
                        d’héritier réservataire. Une procédure pouvant allonger le traitement des dossiers.<br />
                        Le recours au juge à compter du 1er novembre 2017 est supprimé. C’est au Notaire de vérifier que
                        le
                        légataire universel a bien vocation à recueillir l’intégralité du patrimoine et qu’il n’existe
                        pas d’héritiers réservataires. Il en portera mention sur le procès-verbal de dépôt, d’ouverture
                        et de description du testament.<br />
                        L’envoi en possession subsiste en cas d’opposition d’un tiers à l’exercice de ses droits par le
                        légataire.
                    </div>
                    <div style={styles.subTitle}>La renonciation à la succession</div>
                    <div>
                        Un héritier désigné par la loi ou par testament peut accepter la succession ou y renoncer. La
                        renonciation à une succession ne se
                        présumant pas, l’héritier devra l’adresser au tribunal dans le ressort duquel la succession
                        s’est
                        ouverte ou bien devant le notaire, désormais, qui se chargera de l’adresser au tribunal.
                    </div>
                    <div style={styles.subTitle}>L’acceptation de la succession à concurrence de l’actif net</div>
                    <div>
                        À compter du 1er novembre 2017, le code civil permet au notaire de recevoir
                        cette déclaration.
                    </div>
                </div>
                <div id="sincerite" ref={sincerite}>
                    <div style={styles.cardSubTitle}>Affirmation de sincérité</div>
                    <div>
                        <div style={styles.subTitle}>Formule d’usage à insérer au pied de la déclaration de succession :</div>

                    </div>
                    <div>
                        « Le déclarant affirme sincère et véritable la présente déclaration »
                    </div>
                    <div>
                        « Il affirme en outre, sous les peines édictées par l’article 1788 du Code Général des Impôts,
                        que cette déclaration
                        comprend l’argent comptant, les créances, et toutes autres valeurs mobilières françaises ou
                        étrangères qui, à sa connaissance, appartenaient au défunt soit en totalité soit en partie » (le
                        deuxième alinéa n’est pas à écrire si le signataire est un mandataire).
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Rappels;
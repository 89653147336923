import React, { useEffect, useRef, useState } from "react";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import useIsMountedRef from "../../utils/mountedRef";
import { useSnackbar } from "notistack";
import "./breadcrumb.css";
import IconSearch from "../../assets/images/icon_search.png";
import { ToolTipSearch } from "../../components/selects/tooltip_search";
import Fuse from "fuse.js";
import ReactGA from "react-ga4";

const Breadcrumb = (props) => {
  const styles = {
    home: {
      marginLeft: 20,
      marginRight: 10,
    },
    urlLink: {
      color: "#5E91B6",
      textDecoration: "none",
    },
    urlLinkHome: {
      color: "#5E91B6",
      textDecoration: "none",
    },
    ctnBurger: {
      marginTop: "auto",
      paddingTop: 5,
      paddingBottom: 5,
      marginBottom: "auto",
      marginLeft: 30,
      zIndex: 10,
      marginRight: 50,
    },
    elem: {
      marginTop: "auto",
      marginBottom: "auto",
      minWidth: 150,
      maxWidth: 200,
      textAlign: "center",
      cursor: "pointer",
    },
    ctnSearchBar: {
      position: "absolute",
      left: "500px",
      zIndex: 111,
      top: 172,
      backgroundColor: "rgb(249 249 249)",
      width: 480,
      borderRadius: 5,
    },
    ctnSearchBarBorder: {
      width: "100%",
      border: "#5e91b6 1px solid",
      borderRadius: 5,
      boxShadow: "rgba(0, 0, 0, 0.2) 2px 2px 1px",
    },
    searchBar: {
      width: "calc(100% - 12px)",
      borderRadius: 5,
      height: 25,
      outline: "none",
      border: "none",
      backgroundColor: "white",
      paddingLeft: 10,
    },
    ctnSearchBarProposes: {
      textAlign: "right",
    },
    searchlogo: {
      position: "absolute",
      right: "0%",
      top: 4,
    },
    modal: {
      position: "absolute",
      top: 150,
      left: 0,
      backgroundColor: "rgb(89 138 173)",
      width: 300,
      borderBottomRightRadius: 10,
      boxShadow: "5px 5px 2px rgba(0,0,0, 0.2)",
      // height: 365,
      display: "flex",
      flexDirection: "column",
      color: "white",
      zIndex: -5,
    },
    modalBody: {
      width: "90%",
      marginLeft: "auto",
    },
    arrowModal: {
      marginRight: 10,
    },
    modalBodyCtn: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "noWrap",
      alignItems: "center",
      marginTop: 0,
      marginBottom: 0,
    },
    modalBodyHome: {
      marginLeft: 20,
      marginTop: 60,
      marginBottom: 10,
      cursor: "pointer",
    },
    modalBodyTxt: {
      marginTop: 10,
      marginBottom: 10,
      cursor: "pointer",
    },
    modalBottom: {
      width: "100%",
      marginTop: 20,
      borderTop: "1px white solid",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    txtDisconnect: {
      textDecoration: "underline",
      marginTop: 5,
      marginBottom: 15,
      cursor: "pointer",
    },
    txtAccount: {
      fontWeight: "bold",
      marginTop: 15,
      marginBottom: 5,
      color: "white",
      textDecoration: "underline",
      texteDecorationColor: "white",
    },
    proposal_item_ctn: {
      display: "flex",
      flexDirection: "row",
      height: 50,
      width: "100%",
      justifyItems: "space-between",
    },
    proposalItemName: {
      textAlign: "left",
    },
    proposal_item: {
      height: 50,
      width: "100%",
      color: "red",
    },
  };

  const [hoveredItem, setHoveredItem] = useState(null);

  const isMountedRef = useIsMountedRef();

  let simulateurAdded = false;
  let ficheAdded = false;

  let test = window.location.href.split("/");

  test.reverse();
  test.pop();
  test.pop();
  test.pop();
  test.reverse();

  let latest = [];
  for (let i = 0; i < test.length; i++) {
    if (i % 1 === 0 && i !== 0 && isNumeric(test[i])) {
      latest[latest.length - 1] += " " + test[i];
    } else latest.push(test[i]);
    //console.log(latest)
  }

  for (let i = 0; i < test.length; i++) {
    if (isNumeric(test[i]) === true) {
      //console.log("number")
    }
  }

  function isNumeric(str) {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // function pluralApplication(string) {
  //   if (string !== "Simulateur") return string + "s";
  //   else return string;
  // }

  function isValid(string) {
    // if (!isNumeric(string)) {
    if (string.length > 1) return string;
    // }
  }

  const [stateOpenTopMenu, setStateOpenTopMenu] = useState(false);

  const handleChangeStateOpenTopMenu = async (setter, currentValue) => {
    if (currentValue === true) {
      setter(false);
    }
    if (currentValue === false) {
      setter(true);
    }
  };

  function disconnect() {
    localStorage.removeItem("user");
    localStorage.removeItem("remarque");
    localStorage.removeItem("currentEmol");
    localStorage.removeItem("chart");
    window.location = "/";
  }

  const [simulateurs, setSimulateurs] = useState([]);
  const [simulateursProposal, setSimulateursProposal] = useState([]);
  const [fiches, setFiches] = useState([]);
  const [fichesProposal, setFichesProposal] = useState([]);
  const [expertOpinions, setExpertOpinions] = useState([]);
  const [expertOpinionsProposal, setExpertOpinionsProposal] = useState([]);
  const [searchValueInput, setSearchValueInput] = useState("");
  const ctnSearchBarRef = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    var simulateurs = {
      method: "get",
      url: `${API_BASE_URL}/simulateurs`,
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(localStorage.getItem("user"))?.jwt,
      },
    };
    axios(simulateurs)
      .then((response) => {
        if (response.status === 200 && response.data != null) {
          if (isMountedRef.current) {
            setSimulateurs(response.data);
          }
        } else {
          enqueueSnackbar("Une erreur est survenue", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401 && error.response.data != null) {
          if (error.response.data.error == "Other_user_connected") {
            localStorage.removeItem("user");
            localStorage.setItem("other_user_connected", "other_user_connected");
            window.location = "login";
          }
        }
      });

    setFiches([
      // Générales
      {
        href: "/static/media/Les_debours.322d0fdcaab7b25facd0.pdf",
        name: "Les débours",
      },
      {
        href: "/static/media/Specificites_alsace_moselle.ea9faa0adc84c9e8552c.pdf",
        name: "Les spécificités des frais d'actes notariés en Alsace-Moselle",
      },
      {
        href: "/static/media/Les_tarifs_BALO.2f2a46f737534850c542.pdf",
        name: "Les tarifs BALO",
      },
      {
        href: "/static/media/Les_tarifs_BODACC.6b1d2ebeafbcd8c65a7a.pdf",
        name: "Les tarifs BODACC",
      },
      // Immo
      {
        href: "/static/media/Fiscalite_SAFER.88917b4b994609e03fd8.pdf",
        name: "La fiscalité des SAFER",
      },
      {
        href: "/static/media/Check_list_plus_values.3e5de256db288cab1a8e.pdf",
        name: "Check-list sur les plus-values",
      },
      {
        href: "/static/media/Ce_qu_il_ne_faut_pas_oublier_lors_d_une_vente_(check-list).6a6923913d999751a7a2.pdf",
        name: "Check-list lors d'une vente",
      },
      {
        href: "/static/media/Diagnostic_immobilier_le_dossier_de_diagnostic_technique_(DDT).5c9a893fcfb5ad65c932.pdf",
        name: "Les diagnostics immobiliers",
      },
      {
        href: "/static/media/Droits_de_mutation_a_titre_onereux_(DMTO).ebf72add71fc8a4eab62.pdf",
        name: "Les DMTO au 1er Juin 2024",
      },
      {
        href: "/static/media/Le_bail_professionnel_et_le_bail_commercial.7c0de260e96410b0f346.pdf",
        name: "Le bail professionnel et le bail commercial",
      },
      {
        href: "/static/media/La_fiscalite_immobiliere_quel_taux_et_quel_droit_appliquer.502055a105b9292e9a21.pdf",
        name: "La fiscalité immobilière, quel taux et quel droit appliquer ?",
      },
      {
        href: "/static/media/La_location_pour_les_particuliers_quel_bail.f8eec69b87ff10170393.pdf",
        name: "La location pour les particuliers, quel bail ?",
      },
      // Famille
      {
        href: "/static/media/Ce_qu_il_ne_faut_pas_oublier_lors_d_une_succession_(check-list).432d6de5ea4ee29fea44.pdf",
        name: "Check-list lors d'une succession",
      },
      {
        href: "/static/media/Rappel_des_droits_applicables_aux_successions_et_aux_donations.14b9c8fe4291d1a1e12b.pdf",
        name: "Droits applicables aux donations et successions - Rappel",
      },
      {
        href: "/static/media/Synthese_des_exonerations_et_abattements_sur_les_successions.403f1abecb5b1b9f6aa1.pdf",
        name: "Exonérations et abattements sur les successions",
      },
      {
        href: "/static/media/Synthese_des_exonerations_et_abattements_sur_les_donations.f4490b0589748cb34163.pdf",
        name: "Exonérations et abattements sur les donations",
      },
      {
        href: "/static/media/Fiscalite_de_l_assurance_vie_droits_de_succession.2f41bf399316b64a4fb2.pdf",
        name: "La fiscalité de l'assurance vie",
      },
      {
        href: "/static/media/Rappel_sur_les_regles_de_repartition_d_usufruit_et_nue-propriete.b0564a0b465203fb7334.pdf",
        name: "Règles de répartition usufruit nue propriété - Rappel",
      },
    ]);

    setExpertOpinions([
      {
        href: "/static/media/liquidation_CSI_dans_donation_demembree_bien_immobilier.f5f714df1d22ff408881.pdf",
        name: "La liquidation de la CSI dans une donation démembrée d’un bien immobilier",
      },
      {
        href: "/static/media/pma_ou_amp.20f647aaaeccf946980a.pdf",
        name: "La procréation médicalement assistée (PMA) ou assistance médicale à la procréation (AMP) : quelle taxe pour quel acte ?",
      },
      {
        href: "/static/media/liquidation_participation_aux_acquets.a302cea50ff751d5e4ad.pdf",
        name: "Liquidation d’une participation aux acquêts : principes applicables à l’émolument d’acte",
      },
    ]);
  }, [isMountedRef]);

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  useEffect(() => {
    // Fonction pour gérer les clics en dehors de ctnSearchBar
    const handleClickOutside = (event) => {
      if (ctnSearchBarRef.current && !ctnSearchBarRef.current.contains(event.target)) {
        setSearchValueInput("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setStateOpenTopMenu(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const searchWords = searchValueInput.toLowerCase().split(" ");

  useEffect(() => {
    const filteredResultsSimu = simulateurs.filter((item) => {
      const itemName = item.name.toLowerCase();
      return searchWords.every((word) => itemName.includes(word));
    });

    const options = {
      keys: ["name"],
      threshold: 0.4,
    };

    const fuse = new Fuse(simulateurs, options);

    const searchResults = fuse.search(searchValueInput.toLowerCase());

    const newSimulateursProposal = searchResults.map((result, index) => {
      const item = result.item;
      const itemName = item.name;
      const searchTerm = searchValueInput.toLowerCase();
      const parts = itemName.split(new RegExp(`(${searchTerm})`, "gi"));
      const isFirstItem = index === 0 && !simulateurAdded;

      return (
        <ToolTipSearch title={item.name}>
          <div
            key={item.id}
            className={"itemProposal"}
            style={{
              textAlign: isFirstItem ? "left" : "right",
            }}
            onClick={() => {
              if (item.subcategorie[0].shortname == "DMTG")
                window.location.href = "/categorie/" + item.subcategorie[0].categorie.url_name + "/dmtg/" + item.id;
              else if (item.subcategorie[0].categorie.url_name == "autres")
                window.location.href = "/categorie/" + item.subcategorie[0].categorie.url_name + "/others/" + item.id;
              else
                window.location.href =
                  "/categorie/" + item.subcategorie[0].categorie.url_name + "/simulateur/" + item.id;
              ReactGA.event({
                category: "Barre de recherche",
                action: "Clic sur barre de recherche",
                label: "Clic sur barre de recherche",
              });
            }}
            onMouseEnter={() => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            {isFirstItem ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className={"firstItemSearch"}>Simulateurs -</div>
                <div className={"firstItemText"}>
                  {hoveredItem === item
                    ? item.name
                    : item.name.length > 45
                    ? `${item.name.slice(0, 45)}...`
                    : item.name}
                </div>
              </div>
            ) : item.name.length > 45 ? (
              `${item.name.slice(0, 45)}...`
            ) : (
              item.name
            )}
          </div>
        </ToolTipSearch>
      );
    });

    const filteredResultsFiches = fiches.filter((item) => {
      const itemName = item.name.toLowerCase();
      return searchWords.every((word) => itemName.includes(word));
    });

    const fuseFiches = new Fuse(fiches, options);

    const searchResultsFiche = fuseFiches.search(searchValueInput.toLowerCase());

    const newFichesProposal = searchResultsFiche.map((result, index) => {
      const item = result.item;
      const itemName = item.name;
      const searchTerm = searchValueInput.toLowerCase();
      const parts = itemName.split(new RegExp(`(${searchTerm})`, "gi"));
      const isFirstItem = index === 0 && !ficheAdded;

      return (
        <ToolTipSearch title={item.name}>
          <div
            key={item.id}
            className={"itemProposal"}
            style={{
              width: "95%",
              marginLeft: "2.5%",
              letterSpacing: 1,
              height: "30px",
              lineHeight: "30px",
              cursor: "pointer",
              color: "black",
              textAlign: isFirstItem ? "left" : "right",
            }}
            onClick={() => {
              var a = document.createElement("a");
              a.href = item.href;
              a.setAttribute("target", "_blank");
              a.click();
              ReactGA.event({
                category: "Barre de recherche",
                action: "Clic sur barre de recherche",
                label: "Clic sur barre de recherche",
              });
            }}
            onMouseEnter={() => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            {isFirstItem ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className={"firstItemSearch"}>Fiches pratiques -</div>
                <div className={"firstItemText"}>
                  {hoveredItem === item
                    ? item.name
                    : item.name.length > 40
                    ? `${item.name.slice(0, 40)}...`
                    : item.name}
                </div>
              </div>
            ) : item.name.length > 40 ? (
              `${item.name.slice(0, 40)}...`
            ) : (
              item.name
            )}
          </div>
        </ToolTipSearch>
      );
    });

    const filteredResultsExpert = expertOpinions.filter((item) => {
      const itemName = item.name.toLowerCase();
      return searchWords.every((word) => itemName.includes(word));
    });

    const fuseExpert = new Fuse(expertOpinions, options);

    const searchResultsExpert = fuseExpert.search(searchValueInput.toLowerCase());

    const newExpertProposal = searchResultsExpert.map((result, index) => {
      const item = result.item;
      const itemName = item.name;
      const searchTerm = searchValueInput.toLowerCase();
      const parts = itemName.split(new RegExp(`(${searchTerm})`, "gi"));
      const isFirstItem = index === 0 && !ficheAdded;

      return (
        <ToolTipSearch title={item.name}>
          <div
            key={item.id}
            className={"itemProposal"}
            style={{
              width: "95%",
              marginLeft: "2.5%",
              letterSpacing: 1,
              height: "30px",
              lineHeight: "30px",
              cursor: "pointer",
              color: "black",
              textAlign: isFirstItem ? "left" : "right",
            }}
            onClick={() => {
              var a = document.createElement("a");
              a.href = item.href;
              a.setAttribute("target", "_blank");
              a.click();
              ReactGA.event({
                category: "Barre de recherche",
                action: "Clic sur barre de recherche",
                label: "Clic sur barre de recherche",
              });
            }}
            onMouseEnter={() => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            {isFirstItem ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    lineHeight: "30px",
                    fontWeight: "bold",
                    color: "#5e91b6",
                  }}
                >
                  Avis d'experts -
                </div>
                <div
                  style={{
                    textAlign: "right",
                    lineHeight: "30px",
                  }}
                >
                  {item.name.length > 40
                    ? `${`${item.name.substring(0, 1).toUpperCase()}${item.name
                        .substring(1, item.name.length)
                        .toLowerCase()}`.slice(0, 40)}...`
                    : `${item.name.substring(0, 1).toUpperCase()}${item.name
                        .substring(1, item.name.length)
                        .toLowerCase()}`}
                </div>
              </div>
            ) : item.name.length > 40 ? (
              `${`${item.name.substring(0, 1).toUpperCase()}${item.name
                .substring(1, item.name.length)
                .toLowerCase()}`.slice(0, 40)}...`
            ) : (
              `${item.name.substring(0, 1).toUpperCase()}${item.name.substring(1, item.name.length).toLowerCase()}`
            )}
          </div>
        </ToolTipSearch>
      );
    });

    if (searchValueInput.length === 0) {
      setSimulateursProposal(null);
      setExpertOpinionsProposal(null);
      setFichesProposal(null);
      setSearchValueInput("");
    } else {
      setSimulateursProposal(newSimulateursProposal);
      setFichesProposal(newFichesProposal);
      setExpertOpinionsProposal(newExpertProposal);
    }
  }, [searchValueInput]);

  const handleBlur = () => {
    setExpertOpinionsProposal(null);
    setSimulateursProposal(null);
    setFichesProposal(null);
  };

  return (
    <div className={"containerBreadcrumb"}>
      <div style={styles.ctnBurger} ref={wrapperRef}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="24"
          viewBox="0 0 35.643 26.494"
          onClick={(event) => handleChangeStateOpenTopMenu(setStateOpenTopMenu, stateOpenTopMenu)}
          style={{ cursor: "pointer" }}
        >
          <circle cx="438" cy="438" r="438" fill="#fff" stroke="#fff" stroke-miterlimit="10" />
          <g id="Groupe_95" data-name="Groupe 95" transform="translate(-63.934 -18.422)">
            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(66.934 21.422)">
              <path
                id="Tracé_4"
                data-name="Tracé 4"
                d="M4.5,18H34.143"
                transform="translate(-4.5 -7.753)"
                fill="none"
                stroke="#0d373b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="6"
              />
              <path
                id="Tracé_5"
                data-name="Tracé 5"
                d="M4.5,9H34.143"
                transform="translate(-4.5 -9)"
                fill="none"
                stroke="#0d373b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="6"
              />
              <path
                id="Tracé_6"
                data-name="Tracé 6"
                d="M4.5,27H34.143"
                transform="translate(-4.5 -6.506)"
                fill="none"
                stroke="#0d373b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="6"
              />
            </g>
          </g>
        </svg>

        {stateOpenTopMenu === true ? (
          <div style={styles.modal}>
            <div style={styles.modalBody}>
              <div style={styles.modalBodyCtn}>
                <p style={styles.modalBodyHome} onClick={(event) => (window.location = "/")}>
                  Accueil
                </p>
              </div>

              <div style={styles.modalBodyCtn}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="11.471"
                  viewBox="0 0 9 11.471"
                  style={styles.arrowModal}
                >
                  <path
                    id="Icon_ionic-md-arrow-dropright"
                    data-name="Icon ionic-md-arrow-dropright"
                    d="M13.5,9l9,5.736-9,5.736Z"
                    transform="translate(-13.5 -9)"
                    fill="#fbfbfb"
                  />
                </svg>
                <p style={styles.modalBodyTxt} onClick={(event) => (window.location = "/categorie/immobilier")}>
                  Actes immobiliers
                </p>
              </div>

              <div style={styles.modalBodyCtn}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="11.471"
                  viewBox="0 0 9 11.471"
                  style={styles.arrowModal}
                >
                  <path
                    id="Icon_ionic-md-arrow-dropright"
                    data-name="Icon ionic-md-arrow-dropright"
                    d="M13.5,9l9,5.736-9,5.736Z"
                    transform="translate(-13.5 -9)"
                    fill="#fbfbfb"
                  />
                </svg>
                <p style={styles.modalBodyTxt} onClick={(event) => (window.location = "/categorie/famille")}>
                  Actes de la famille
                </p>
              </div>

              <div style={styles.modalBodyCtn}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="11.471"
                  viewBox="0 0 9 11.471"
                  style={styles.arrowModal}
                >
                  <path
                    id="Icon_ionic-md-arrow-dropright"
                    data-name="Icon ionic-md-arrow-dropright"
                    d="M13.5,9l9,5.736-9,5.736Z"
                    transform="translate(-13.5 -9)"
                    fill="#fbfbfb"
                  />
                </svg>
                <p style={styles.modalBodyTxt} onClick={(event) => (window.location = "/categorie/prets")}>
                  Actes de prêts, garanties et mainlevées
                </p>
              </div>

              <div style={styles.modalBodyCtn}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="11.471"
                  viewBox="0 0 9 11.471"
                  style={styles.arrowModal}
                >
                  <path
                    id="Icon_ionic-md-arrow-dropright"
                    data-name="Icon ionic-md-arrow-dropright"
                    d="M13.5,9l9,5.736-9,5.736Z"
                    transform="translate(-13.5 -9)"
                    fill="#fbfbfb"
                  />
                </svg>
                <p style={styles.modalBodyTxt} onClick={(event) => (window.location = "/categorie/affaires")}>
                  Actes d'affaires
                </p>
              </div>
            </div>

            <div style={styles.modalBottom}>
              <div>
                <a href={"/moncompte"}>
                  <p style={styles.txtAccount}>Mon compte</p>
                </a>
                <p
                  style={styles.txtDisconnect}
                  onClick={(e) => {
                    disconnect();
                  }}
                >
                  Se déconnecter
                </p>
              </div>

              <svg xmlns="http://www.w3.org/2000/svg" width="56.304" height="54.771" viewBox="0 0 56.304 54.771">
                <path
                  id="Icon_material-account-circle"
                  data-name="Icon material-account-circle"
                  d="M31.152,3C15.612,3,3,15.269,3,30.385S15.612,57.771,31.152,57.771,59.3,45.5,59.3,30.385,46.692,3,31.152,3Zm0,8.216A8.322,8.322,0,0,1,39.6,19.431a8.449,8.449,0,0,1-16.891,0A8.322,8.322,0,0,1,31.152,11.216Zm0,38.887a20.423,20.423,0,0,1-16.891-8.818c.084-5.45,11.261-8.435,16.891-8.435,5.6,0,16.807,2.985,16.891,8.435A20.423,20.423,0,0,1,31.152,50.1Z"
                  transform="translate(-3 -3)"
                  fill="#fbfbfb"
                />
              </svg>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16.369" height="21.826" viewBox="0 0 16.369 21.826">
          <path
            id="Icon_awesome-map-marker-alt"
            data-name="Icon awesome-map-marker-alt"
            d="M7.343,21.385C1.15,12.406,0,11.485,0,8.185a8.185,8.185,0,0,1,16.369,0c0,3.3-1.15,4.221-7.343,13.2a1.024,1.024,0,0,1-1.682,0Zm.841-9.79a3.41,3.41,0,1,0-3.41-3.41A3.41,3.41,0,0,0,8.185,11.595Z"
            fill="#5E91B6"
          />
        </svg>
      </div>
      <div style={styles.home}>
        <a href={`/`} style={styles.urlLinkHome}>
          Accueil
        </a>
      </div>

      {isValid(latest[0]) ? (
        <>
          <div className={"slash"}>/</div>

          {window.location.pathname === "/moncompte" ? (
            <div>Mon Compte</div>
          ) : (
            <div className={"slash"}>
              {latest[0] === "fiches" ? (
                <a href={"/fiches"} style={styles.urlLink}>
                  <div>Fiches Pratiques</div>
                </a>
              ) : latest[0] === "categorie" ? (
                latest[1].includes("#") ? (
                  <a href={"/categorie/" + latest[1].substring(0, latest[1].indexOf("#"))} style={styles.urlLink}>
                    {latest[1].substring(0, latest[1].indexOf("#")) === "prets" ? (
                      <div>Prêts, garanties et mainlevées</div>
                    ) : (
                      capitalizeFirstLetter(latest[1].substring(0, latest[1].indexOf("#")))
                    )}
                  </a>
                ) : (
                  <a href={"/categorie/" + latest[1]} style={styles.urlLink}>
                    {latest[1] === "prets" ? (
                      <div>Prêts, garanties et mainlevées</div>
                    ) : (
                      capitalizeFirstLetter(latest[1])
                    )}
                  </a>
                )
              ) : latest[0] === "avis" ? (
                // Nouvelle condition
                <a href={"/avis"} style={styles.urlLink}>
                  <div>Avis d'Experts</div>
                </a>
              ) : latest[0].includes("#") ? (
                <a href={"/" + latest[0].substring(0, latest[0].indexOf("#"))} style={styles.urlLink}>
                  {capitalizeFirstLetter(latest[0].substring(0, latest[0].indexOf("#")))}
                </a>
              ) : latest[0] === "pratiques" ? (
                // Nouvelle condition
                <a href={"/pratiques"} style={styles.urlLink}>
                  <div>Manuels PRATIQUES</div>
                </a>
              ) : latest[0] === "SchemaFiscalite" ? (
                // Nouvelle condition
                <a href={"/SchemaFiscalite"} style={styles.urlLink}>
                  <div>La fiscalité immobilière</div>
                </a>
              ) : (
                <a href={"/" + latest[0]} style={styles.urlLink}>
                  {capitalizeFirstLetter(latest[0])}
                </a>
              )}
            </div>
          )}
        </>
      ) : (
        <></>
      )}
      <img src={IconSearch} alt="picto loupe" className={"pictoLoupeBreadcrumb"} />
      <div ref={ctnSearchBarRef} className={"ctnSearchBar"}>
        <div style={styles.ctnSearchBarBorder}>
          <div className={"searchContainer"}>
            <input
              type={"text"}
              style={styles.searchBar}
              placeholder={"Recherche rapide"}
              value={searchValueInput}
              onChange={(e) => setSearchValueInput(e.target.value)}
            />
            <span
              className="clear-icon"
              style={styles.clearIcon}
              onClick={() => {
                setSearchValueInput("");
              }}
            >
              ✕
            </span>
          </div>

          <div style={styles.ctnSearchBarProposes}>
            {simulateursProposal} {fichesProposal} {expertOpinionsProposal}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;

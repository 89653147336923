import React, {useEffect, useState} from "react";
import {setSimulateurObject} from "../../../actions/simulateur.action";
import {useDispatch} from 'react-redux';
import {styles} from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import TextField from "@mui/material/TextField";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import moment from "moment"
import SelectCommon from "../../../components/selects/select.common";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";
import ButtonYesno from "../../../components/buttons/button.yesno";
import {useSnackbar} from "notistack";

// Plus-value immo 
// Id simulateur 106

function Simulateur_106(props, simulateur, isParent) {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const [departement, setDepartement] = useState(1)
    const [type_bien, setBien] = useState(0)
    const [prix_vente, setPrixVente] = useState(0)
    const [frais_vente, setFraisVente] = useState(0)
    const [date_vente, setDateVente] = useState(new Date())
    const [prix_acquisition, setPrixAcquisition] = useState(0)
    const [frais_acquisition, setFraisAcquisition] = useState(0)
    const [date_acquisition, setDateAcquisition] = useState(new Date())
    const [montant_travaux, setTravaux] = useState(0)
    const [showForfait, setShowForfait] = useState(false)
    const [forfaitTravaux, setForfaitTravaux] = useState(0)
    const [fraisDefaut, setFraisDefaut] = useState(0)

    const handleChange = async (setter, value) => {
        if(setter === setDateVente && date_acquisition !== undefined && date_acquisition !== null) {
            if (value < date_acquisition) {
                enqueueSnackbar("La date de vente doit être postérieure à la date d'acquisition", {
                    variant: "error",
                });
                return;
            }
        }
        if(setter === setDateAcquisition && date_vente !== undefined && date_vente !== null) {
            if (value > date_vente) {
                enqueueSnackbar("La date de vente doit être postérieure à la date d'acquisition", {
                    variant: "error",
                });
                return;
            }
        }
        setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    useEffect(() => {
        if (date_vente !== undefined && date_vente !== null && date_acquisition !== undefined && date_acquisition !== null) {
            if (((parseInt(moment(date_vente.toString()).utc(true).format("x")) - parseInt(moment(date_acquisition.toString()).utc(true).format("x"))) / 31556952000) > 5) {
                setShowForfait(true)
            } else {
                setShowForfait(false)
            }
        }
    }, [date_vente, date_acquisition]);

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "inputs": {}
            };

            if (setter === setBien) {
                Object.assign(object['inputs'], {'type_bien': parseInt(value)})
                Object.assign(object['inputs'], {'prix_vente': parseInt(prix_vente)})
                Object.assign(object['inputs'], {'frais_vente': parseInt(frais_vente)})
                Object.assign(object['inputs'], {'prix_acquisition': parseInt(prix_acquisition)})
                Object.assign(object['inputs'], {'frais_defaut': parseInt(fraisDefaut)})
                Object.assign(object['inputs'], {'frais_acquisition': parseInt(frais_acquisition)})
                Object.assign(object['inputs'], {'forfait_travaux': parseInt(forfaitTravaux)})
                Object.assign(object['inputs'], {'montant_travaux': parseInt(montant_travaux)})
                if (date_vente !== undefined && date_vente !== null) {
                    Object.assign(object['inputs'], {'date_vente': parseInt(moment(date_vente.toString()).utc(true).format("x"))})
                }
                if (date_acquisition !== undefined && date_acquisition !== null) {
                    Object.assign(object['inputs'], {'date_acquisition': parseInt(moment(date_acquisition.toString()).utc(true).format("x"))})
                }
                object.departementid = parseInt(departement)
            }

            if (setter === setPrixVente) {
                Object.assign(object['inputs'], {'type_bien': parseInt(type_bien)})
                Object.assign(object['inputs'], {'prix_vente': parseInt(value)})
                Object.assign(object['inputs'], {'frais_vente': parseInt(frais_vente)})
                Object.assign(object['inputs'], {'prix_acquisition': parseInt(prix_acquisition)})
                Object.assign(object['inputs'], {'frais_defaut': parseInt(fraisDefaut)})
                Object.assign(object['inputs'], {'frais_acquisition': parseInt(frais_acquisition)})
                Object.assign(object['inputs'], {'forfait_travaux': parseInt(forfaitTravaux)})
                Object.assign(object['inputs'], {'montant_travaux': parseInt(montant_travaux)})
                if (date_vente !== undefined && date_vente !== null) {
                    Object.assign(object['inputs'], {'date_vente': parseInt(moment(date_vente.toString()).utc(true).format("x"))})
                }
                if (date_acquisition !== undefined && date_acquisition !== null) {
                    Object.assign(object['inputs'], {'date_acquisition': parseInt(moment(date_acquisition.toString()).utc(true).format("x"))})
                }
                object.departementid = parseInt(departement)
            }

            if (setter === setFraisVente) {
                Object.assign(object['inputs'], {'type_bien': parseInt(type_bien)})
                Object.assign(object['inputs'], {'prix_vente': parseInt(prix_vente)})
                Object.assign(object['inputs'], {'frais_vente': parseInt(value)})
                Object.assign(object['inputs'], {'prix_acquisition': parseInt(prix_acquisition)})
                Object.assign(object['inputs'], {'frais_defaut': parseInt(fraisDefaut)})
                Object.assign(object['inputs'], {'frais_acquisition': parseInt(frais_acquisition)})
                Object.assign(object['inputs'], {'forfait_travaux': parseInt(forfaitTravaux)})
                Object.assign(object['inputs'], {'montant_travaux': parseInt(montant_travaux)})
                if (date_vente !== undefined && date_vente !== null) {
                    Object.assign(object['inputs'], {'date_vente': parseInt(moment(date_vente.toString()).utc(true).format("x"))})
                }
                if (date_acquisition !== undefined && date_acquisition !== null) {
                    Object.assign(object['inputs'], {'date_acquisition': parseInt(moment(date_acquisition.toString()).utc(true).format("x"))})
                }
                object.departementid = parseInt(departement)
            }

            if (setter === setPrixAcquisition) {
                Object.assign(object['inputs'], {'type_bien': parseInt(type_bien)})
                Object.assign(object['inputs'], {'prix_vente': parseInt(prix_vente)})
                Object.assign(object['inputs'], {'frais_vente': parseInt(frais_vente)})
                Object.assign(object['inputs'], {'prix_acquisition': parseInt(value)})
                Object.assign(object['inputs'], {'frais_defaut': parseInt(fraisDefaut)})
                Object.assign(object['inputs'], {'frais_acquisition': parseInt(frais_acquisition)})
                Object.assign(object['inputs'], {'forfait_travaux': parseInt(forfaitTravaux)})
                Object.assign(object['inputs'], {'montant_travaux': parseInt(montant_travaux)})
                if (date_vente !== undefined && date_vente !== null) {
                    Object.assign(object['inputs'], {'date_vente': parseInt(moment(date_vente.toString()).utc(true).format("x"))})
                }
                if (date_acquisition !== undefined && date_acquisition !== null) {
                    Object.assign(object['inputs'], {'date_acquisition': parseInt(moment(date_acquisition.toString()).utc(true).format("x"))})
                }
                object.departementid = parseInt(departement)
            }

            if (setter === setFraisAcquisition) {
                Object.assign(object['inputs'], {'type_bien': parseInt(type_bien)})
                Object.assign(object['inputs'], {'prix_vente': parseInt(prix_vente)})
                Object.assign(object['inputs'], {'frais_vente': parseInt(frais_vente)})
                Object.assign(object['inputs'], {'prix_acquisition': parseInt(prix_acquisition)})
                Object.assign(object['inputs'], {'frais_defaut': parseInt(fraisDefaut)})
                Object.assign(object['inputs'], {'frais_acquisition': parseInt(value)})
                Object.assign(object['inputs'], {'forfait_travaux': parseInt(forfaitTravaux)})
                Object.assign(object['inputs'], {'montant_travaux': parseInt(montant_travaux)})
                if (date_vente !== undefined && date_vente !== null) {
                    Object.assign(object['inputs'], {'date_vente': parseInt(moment(date_vente.toString()).utc(true).format("x"))})
                }
                if (date_acquisition !== undefined && date_acquisition !== null) {
                    Object.assign(object['inputs'], {'date_acquisition': parseInt(moment(date_acquisition.toString()).utc(true).format("x"))})
                }
                object.departementid = parseInt(departement)
            }

            if (setter === setTravaux) {
                Object.assign(object['inputs'], {'type_bien': parseInt(type_bien)})
                Object.assign(object['inputs'], {'prix_vente': parseInt(prix_vente)})
                Object.assign(object['inputs'], {'frais_vente': parseInt(frais_vente)})
                Object.assign(object['inputs'], {'prix_acquisition': parseInt(prix_acquisition)})
                Object.assign(object['inputs'], {'frais_defaut': parseInt(fraisDefaut)})
                Object.assign(object['inputs'], {'frais_acquisition': parseInt(frais_acquisition)})
                Object.assign(object['inputs'], {'forfait_travaux': parseInt(forfaitTravaux)})
                Object.assign(object['inputs'], {'montant_travaux': parseInt(value)})
                if (date_vente !== undefined && date_vente !== null) {
                    Object.assign(object['inputs'], {'date_vente': parseInt(moment(date_vente.toString()).utc(true).format("x"))})
                }
                if (date_acquisition !== undefined && date_acquisition !== null) {
                    Object.assign(object['inputs'], {'date_acquisition': parseInt(moment(date_acquisition.toString()).utc(true).format("x"))})
                }
                object.departementid = parseInt(departement)
            }

            if (setter === setDateVente) {
                Object.assign(object['inputs'], {'type_bien': parseInt(type_bien)})
                Object.assign(object['inputs'], {'prix_vente': parseInt(prix_vente)})
                Object.assign(object['inputs'], {'frais_vente': parseInt(frais_vente)})
                Object.assign(object['inputs'], {'prix_acquisition': parseInt(prix_acquisition)})
                Object.assign(object['inputs'], {'frais_defaut': parseInt(fraisDefaut)})
                Object.assign(object['inputs'], {'frais_acquisition': parseInt(frais_acquisition)})
                Object.assign(object['inputs'], {'forfait_travaux': parseInt(forfaitTravaux)})
                Object.assign(object['inputs'], {'montant_travaux': parseInt(montant_travaux)})
                if (value !== undefined && value !== null) {
                    Object.assign(object['inputs'], {'date_vente': parseInt(moment(value.toString()).utc(true).format("x"))})
                }
                if (date_acquisition !== undefined && date_acquisition !== null) {
                    Object.assign(object['inputs'], {'date_acquisition': parseInt(moment(date_acquisition.toString()).utc(true).format("x"))})
                }
                object.departementid = parseInt(departement)
            }

            if (setter === setDateAcquisition) {
                Object.assign(object['inputs'], {'type_bien': parseInt(type_bien)})
                Object.assign(object['inputs'], {'prix_vente': parseInt(prix_vente)})
                Object.assign(object['inputs'], {'frais_vente': parseInt(frais_vente)})
                Object.assign(object['inputs'], {'prix_acquisition': parseInt(prix_acquisition)})
                Object.assign(object['inputs'], {'frais_defaut': parseInt(fraisDefaut)})
                Object.assign(object['inputs'], {'frais_acquisition': parseInt(frais_acquisition)})
                Object.assign(object['inputs'], {'forfait_travaux': parseInt(forfaitTravaux)})
                Object.assign(object['inputs'], {'montant_travaux': parseInt(montant_travaux)})
                if (date_vente !== undefined && date_vente !== null) {
                    Object.assign(object['inputs'], {'date_vente': parseInt(moment(date_vente.toString()).utc(true).format("x"))})
                }
                if (value !== undefined && value !== null) {
                    Object.assign(object['inputs'], {'date_acquisition': parseInt(moment(value.toString()).utc(true).format("x"))})
                }
                object.departementid = parseInt(departement)
            }

            if (setter === setForfaitTravaux) {
                Object.assign(object['inputs'], {'type_bien': parseInt(type_bien)})
                Object.assign(object['inputs'], {'prix_vente': parseInt(prix_vente)})
                Object.assign(object['inputs'], {'frais_vente': parseInt(frais_vente)})
                Object.assign(object['inputs'], {'prix_acquisition': parseInt(prix_acquisition)})
                Object.assign(object['inputs'], {'frais_defaut': parseInt(fraisDefaut)})
                Object.assign(object['inputs'], {'frais_acquisition': parseInt(frais_acquisition)})
                Object.assign(object['inputs'], {'forfait_travaux': parseInt(value)})
                Object.assign(object['inputs'], {'montant_travaux': parseInt(montant_travaux)})
                if (date_vente !== undefined && date_vente !== null) {
                    Object.assign(object['inputs'], {'date_vente': parseInt(moment(date_vente.toString()).utc(true).format("x"))})
                }
                if (date_acquisition !== undefined && date_acquisition !== null) {
                    Object.assign(object['inputs'], {'date_acquisition': parseInt(moment(date_acquisition.toString()).utc(true).format("x"))})
                }
                object.departementid = parseInt(departement)
            }

            if (setter === setFraisDefaut) {
                Object.assign(object['inputs'], {'type_bien': parseInt(type_bien)})
                Object.assign(object['inputs'], {'prix_vente': parseInt(prix_vente)})
                Object.assign(object['inputs'], {'frais_vente': parseInt(frais_vente)})
                Object.assign(object['inputs'], {'prix_acquisition': parseInt(prix_acquisition)})
                Object.assign(object['inputs'], {'frais_defaut': parseInt(value)})
                Object.assign(object['inputs'], {'frais_acquisition': parseInt(frais_acquisition)})
                Object.assign(object['inputs'], {'forfait_travaux': parseInt(forfaitTravaux)})
                Object.assign(object['inputs'], {'montant_travaux': parseInt(montant_travaux)})
                if (date_vente !== undefined && date_vente !== null) {
                    Object.assign(object['inputs'], {'date_vente': parseInt(moment(date_vente.toString()).utc(true).format("x"))})
                }
                if (date_acquisition !== undefined && date_acquisition !== null) {
                    Object.assign(object['inputs'], {'date_acquisition': parseInt(moment(date_acquisition.toString()).utc(true).format("x"))})
                }
                object.departementid = parseInt(departement)
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    const typeBien = [
        {
            id: 1,
            name: "Maison ou appartement"
        },
        {
            id: 2,
            name: "Terrain"
        },
        {
            id: 3,
            name: "Terrain avec construction"
        }
    ]

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id}/>

                <TextfieldRemarques handleChange={handleChangeRemarque}/>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Type de bien
                    </div>
                    <SelectCommon items={typeBien} defaultValue={1} nameField={"name"} optionValue={"id"}
                                  handleChange={handleChange} setter={setBien}/>
                </div>

                <div style={styles.cardTitle}>
                    Vente
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le prix de vente ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setPrixVente}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le montant des frais engagés (commission d’agence payée par le vendeur,
                        diagnostics,...) ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setFraisVente}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Indiquer la date de la vente
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker

                            inputFormat="DD/MM/YYYY"
                            value={date_vente}
                            onChange={((val) => {
                                handleChange(setDateVente, val)
                            })}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>

                <div style={styles.cardTitle}>
                    Acquisition
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le prix d’acquisition ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setPrixAcquisition}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Souhaitez-vous utiliser le montant des frais d'achat par défaut (7.5% du coût d'acquisition) ?
                    </div>
                    <div>
                        <ButtonYesno handleChange={handleChange} setter={setFraisDefaut} fieldUndefined={false}
                                     defaultValue={0}/>
                    </div>
                </div>

                {fraisDefaut !== 1 ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Quel est le montant des frais d'achat ?
                        </div>
                        <TextfieldNumber
                            handleChange={handleChange}
                            type={"number"}
                            placeholder={"Indiquer le montant"}
                            setter={setFraisAcquisition}
                        />
                    </div>
                    :
                    <></>
                }

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Indiquer la date d'acquisition
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            inputFormat="DD/MM/YYYY"
                            value={date_acquisition}
                            onChange={((val) => {
                                handleChange(setDateAcquisition, val)
                            })}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>

                {showForfait ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Souhaitez-vous bénéficier du forfait de 15% ?
                        </div>
                        <div>
                            <ButtonYesno handleChange={handleChange} setter={setForfaitTravaux} fieldUndefined={false}
                                         defaultValue={0}/>
                        </div>
                    </div>
                    :
                    <></>
                }

                {forfaitTravaux !== 1 ?
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Quel est le montant des travaux ? (Au-delà de 5 ans, forfait de 15%)
                        </div>
                        <TextfieldNumber
                            handleChange={handleChange}
                            type={"number"}
                            placeholder={"Indiquer le montant"}
                            setter={setTravaux}
                        />
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    )
}

export default Simulateur_106;

import React, { useState } from 'react';
import { buttonScrollCss } from '../../stylesheets/button.scroll.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const ScrollButton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (

        <div style={buttonScrollCss.scrollButton} onClick={scrollToTop}>
            <div style={{
                display: visible ? 'inline' : 'none',
                cursor: 'pointer',
                border: "1px solid transparent",
                borderRadius: "5px",
                backgroundColor: "rgb(94, 145, 182, 0.6)",
                width: "171px",
            }} >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center"
                }} >
                    <KeyboardArrowUpIcon
                        style={{
                            fontSize: '25px',
                            color: "white",
                            marginTop: "-4px"
                        }} />
                    <div
                        style={{
                            fontSize: '15px',
                            color: "white",
                            marginTop: "-4px",
                            marginBottom: "2px"
                        }}>
                        Retour en haut de page
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScrollButton;
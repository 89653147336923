import React from "react";
import "./popUp.css";
import VideoNews from "../../assets/videos/Nouveautes.mp4";

const VideoPopup = ({ onClose, slides }) => {
  const closePopup = () => {
    onClose();
  };

  return (
    <div className="info-popup-container">
      <div className="video-popup">
        <div onClick={closePopup}>
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 40 40"
            style={{ enableBackground: "new 0 0 40 40" }}
            xmlSpace="preserve"
            className="close-button"
          >
            <circle fill="#0a354f" stroke="#ffffff" strokeWidth="1" strokeMiterlimit="8" cx="20" cy="20" r="19" />
            <g>
              <g>
                <g>
                  <path
                    fill="#ffffff"
                    d="M8.21,9.97c6.37,6.37,12.73,12.73,19.1,19.1c0.91,0.91,1.81,1.81,2.72,2.72c1.14,1.14,2.91-0.63,1.77-1.77
                  c-6.37-6.37-12.73-12.73-19.1-19.1c-0.91-0.91-1.81-1.81-2.72-2.72C8.84,7.07,7.07,8.83,8.21,9.97L8.21,9.97z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    fill="#ffffff"
                    d="M9.97,31.8c6.37-6.37,12.73-12.73,19.1-19.1c0.91-0.91,1.81-1.81,2.72-2.72c1.14-1.14-0.63-2.91-1.77-1.77
                  c-6.37,6.37-12.73,12.73-19.1,19.1c-0.91,0.91-1.81,1.81-2.72,2.72C7.07,31.17,8.83,32.93,9.97,31.8L9.97,31.8z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <video controls className="videoPopUp">
          <source src={VideoNews} type="video/mp4" />
          Votre navigateur ne prend pas en charge la lecture de vidéos HTML5.
        </video>
      </div>
    </div>
  );
};

export default VideoPopup;

export const buttonScrollCss = {
    scrollButton: {
        position: "fixed",
        width: "100%",
        bottom: "25px",
        zIndex: 1,
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
    },
};

import React, { useState } from 'react';
import Colors from "../../useclass/colors"
import Mail from '../../components/mail/mail';

const InfosFooter = () => {
    const styles = {
        container: {
            width: "100vw",
            // height: 45,
            padding: 0,
            margin: 0,
            backgroundColor: Colors.lightGrey,
            paddingTop: 10,
            paddingBottom: 10

        },
        containerBottom: {
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-evenly"
        },
        ctnBlock: {
            display: "flex",
            flexDirection: "column",
            borderLeft: "3px white solid",
            marginTop: 10,
            marginBottom: 10,
            paddingLeft: 30
        },
        ctnBody: {
            display: "flex",
            flexDirection: "column",
        },
        bodyLine: {
            display: "flex",
            flexDirection: "row",
        },
        logo: {
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: 10
        },
        bodyText: {
            marginTop: 5,
            marginBottom: 5,
            cursor:"pointer",
        },
        bodyTextLink:{
            marginTop: 5,
            marginBottom: 5,
            cursor:"pointer",
            textDecoration:"none",
            color:"black",
        },
        blockTitle: {
            marginBottom: 5
        },
        remarques: {
            zIndex: 5
        },
    };
    const [open, setOpen] = useState(false);
    const handleChange = async (setter, value) => {
        setter(value);
    };
      
    return (
        <div style={styles.container}>
            <div style={styles.containerBottom}>
                <div style={styles.ctnBlock}>
                    <h3 style={styles.blockTitle}>NOUS CONTACTER</h3>
                    <div style={styles.ctnBody}>
                        <div style={styles.bodyLine}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.548" height="16.759"
                                 viewBox="0 0 17.548 16.759" style={styles.logo}>
                                <g id="Icon_ionic-ios-chatbubbles" data-name="Icon ionic-ios-chatbubbles"
                                   transform="translate(-3.375 -3.375)">
                                    <path id="Tracé_11" data-name="Tracé 11"
                                          d="M20.856,14.357a.976.976,0,0,1,.133-.492,1.357,1.357,0,0,1,.085-.125A6.528,6.528,0,0,0,22.185,10.1a6.853,6.853,0,0,0-7-6.728A6.95,6.95,0,0,0,8.326,8.729a6.474,6.474,0,0,0-.149,1.378,6.834,6.834,0,0,0,6.889,6.813,8.3,8.3,0,0,0,1.9-.31c.455-.125.906-.29,1.023-.334a1.065,1.065,0,0,1,.375-.068,1.047,1.047,0,0,1,.407.081l2.284.81a.545.545,0,0,0,.157.04.321.321,0,0,0,.322-.322.518.518,0,0,0-.02-.109Z"
                                          transform="translate(-1.262)"/>
                                    <path id="Tracé_12" data-name="Tracé 12"
                                          d="M14.273,21.322c-.145.04-.33.085-.532.129a7.436,7.436,0,0,1-1.37.181A6.834,6.834,0,0,1,5.482,14.82a7.617,7.617,0,0,1,.06-.862c.024-.173.052-.346.093-.516s.089-.363.141-.54l-.322.286A6,6,0,0,0,3.375,17.7a5.929,5.929,0,0,0,1,3.3c.093.141.145.25.129.322s-.479,2.5-.479,2.5a.323.323,0,0,0,.109.31.328.328,0,0,0,.205.073.289.289,0,0,0,.117-.024l2.26-.89A.63.63,0,0,1,7.2,23.3a6.78,6.78,0,0,0,2.445.483,6.327,6.327,0,0,0,4.838-2.22s.129-.177.278-.387C14.611,21.226,14.442,21.278,14.273,21.322Z"
                                          transform="translate(0 -4.068)"/>
                                </g>
                            </svg>
                            <p style={styles.bodyText} onClick={(event) => handleChange(setOpen, true)}>Vos remarques</p> <Mail state={open} setOpen={setOpen} floatItem={true}  style={{ zIndex: 100 }}/>
                        </div>
                        <div style={styles.bodyLine}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.103" height="16.103"
                                 viewBox="0 0 16.103 16.103" style={styles.logo}>
                                <path id="Icon_awesome-phone" data-name="Icon awesome-phone"
                                      d="M15.518.773,12.247.018a.76.76,0,0,0-.865.437L9.872,3.978a.753.753,0,0,0,.217.881L12,6.419a11.656,11.656,0,0,1-5.573,5.573l-1.56-1.906a.754.754,0,0,0-.881-.217L.459,11.379a.764.764,0,0,0-.44.868l.755,3.271a.755.755,0,0,0,.736.585A14.592,14.592,0,0,0,16.1,1.509.754.754,0,0,0,15.518.773Z"
                                      transform="translate(0.001 0.001)"/>
                            </svg>
                            <p style={styles.bodyText}>04 91 25 13 38</p>
                        </div>
                        <div style={styles.bodyLine}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.323" height="12.368"
                                 viewBox="0 0 18.323 12.368" style={styles.logo}>
                                <path id="Icon_zocial-email" data-name="Icon zocial-email"
                                      d="M.072,15.341V5.163q0-.018.053-.336l5.99,5.124L.143,15.694a1.5,1.5,0,0,1-.071-.353Zm.8-11.22a.761.761,0,0,1,.3-.053H17.3a1,1,0,0,1,.318.053L11.61,9.263l-.8.636-1.573,1.29L7.67,9.9l-.8-.636ZM.885,16.383,6.91,10.605,9.242,12.5l2.332-1.891L17.6,16.383a.848.848,0,0,1-.3.053H1.167a.8.8,0,0,1-.283-.053ZM12.369,9.952l5.972-5.124a1.055,1.055,0,0,1,.053.336V15.341a1.354,1.354,0,0,1-.053.353Z"
                                      transform="translate(-0.072 -4.068)"/>
                            </svg>
                            <a style={styles.bodyTextLink} href="mailto:support@langloys.com">support@langloys.com</a>
                        </div>
                    </div>
                </div>


 {/*               <div style={styles.ctnBlock}>
                    <h3 style={styles.blockTitle}>EN SAVOIR PLUS</h3>
                    <div style={styles.ctnBody}>
                        <div style={styles.bodyLine}>
                            <p style={styles.bodyText}>S’informer</p>
                        </div>
                        <div style={styles.bodyLine}>
                            <p style={styles.bodyText}>Commander</p>
                        </div>
                        <div style={styles.bodyLine}>
                            <p style={styles.bodyText}>Vos remarques</p>
                        </div>
                    </div>
                </div>*/}


                <div style={styles.ctnBlock}>
                    <h3 style={styles.blockTitle}>ÉDITIONS LANGLOŸS</h3>
                    <div style={styles.ctnBody}>
                        <div style={styles.bodyLine}>
                            <p style={styles.bodyText}>Business Lounge</p>
                        </div>
                        <div style={styles.bodyLine}>
                            <p style={styles.bodyText}>395 rue Paradis</p>
                        </div>
                        <div style={styles.bodyLine}>
                            <p style={styles.bodyText}>13008 MARSEILLE</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfosFooter;

import {createBrowserHistory} from 'history';
import {Router} from "react-router-dom";
import Routes from "./Routes";
import MessagePopin from "./components/messagepopin/messagepopin";
import React, {useState} from "react";
import Colors from "./useclass/colors";
import Mail from "./components/mail/mail";
import buttonScrollCss from "./stylesheets/button.scroll.css"
import ScrollButton from "./components/buttons/button.scroll";

const history = createBrowserHistory();

function App() {
    const [open, setOpen] = useState(false);
    const handleChange = async (setter, value) => {
        setter(value);
    };
    const styles = {
        messageLogo: {
            position: "fixed",
            right: 15,
            bottom: 90,
            cursor: "pointer"
        }
    };


    return (
        <>
            <Router history={history}>
                <Routes/>
            </Router>
            {
                window.location.href.split('/').length > 4 ?
                    <div style={styles.messageLogo}
                         onClick={(event) => handleChange(setOpen, true)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 66 66"
                        >
                            <g id="Icon_ionic-ios-chatbubbles" data-name="Icon ionic-ios-chatbubbles"
                               transform="translate(-3.375 -3.375)">
                                <path id="Tracé_9" data-name="Tracé 9"
                                      d="M58.106,46.624a3.844,3.844,0,0,1,.524-1.936,5.347,5.347,0,0,1,.333-.492A25.707,25.707,0,0,0,63.341,29.87c.048-14.628-12.3-26.5-27.558-26.5-13.311,0-24.417,9.059-27.019,21.085a25.5,25.5,0,0,0-.587,5.426,26.913,26.913,0,0,0,27.13,26.828A32.674,32.674,0,0,0,42.8,55.493c1.793-.492,3.57-1.142,4.03-1.317a4.194,4.194,0,0,1,1.475-.27,4.125,4.125,0,0,1,1.6.317l9,3.189a2.145,2.145,0,0,0,.619.159A1.263,1.263,0,0,0,60.787,56.3a2.038,2.038,0,0,0-.079-.428Z"
                                      transform="translate(6.034 0)" fill="rgb(94, 145, 182)"/>
                                <path id="Tracé_10" data-name="Tracé 10"
                                      d="M46.291,46.061c-.571.159-1.3.333-2.094.508a29.282,29.282,0,0,1-5.394.714,26.913,26.913,0,0,1-27.13-26.828,30,30,0,0,1,.238-3.4c.1-.682.206-1.364.365-2.031.159-.714.349-1.428.555-2.126l-1.269,1.126A23.611,23.611,0,0,0,3.375,31.782,23.347,23.347,0,0,0,7.31,44.792c.365.555.571.984.508,1.269S5.929,55.9,5.929,55.9a1.273,1.273,0,0,0,.428,1.222,1.292,1.292,0,0,0,.809.286,1.138,1.138,0,0,0,.46-.1l8.9-3.506a2.479,2.479,0,0,1,1.9.032,26.7,26.7,0,0,0,9.63,1.9A24.918,24.918,0,0,0,47.116,47s.508-.7,1.095-1.523C47.624,45.68,46.957,45.886,46.291,46.061Z"
                                      transform="translate(0 11.97)" fill="rgb(94, 145, 182)"/>
                            </g>
                        </svg>
                    </div>

                    :
                    <></>
            }
            <Mail state={open} setOpen={setOpen} floatItem={true}/>
            <ScrollButton />

        </>
    );
}

export default App;

import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import SelectCommon from "../../../components/selects/select.common";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";
import { ToolTipPerso } from "../../../components/selects/tooltip_perso";

// Cession de droits sociaux
// Id simulateur 83

function Simulateur_83(props, simulateur, isParent, departements) {
  const dispatch = useDispatch();

  const currentIdSimu = 83;
  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [departement, setDepartement] = useState(0);
  const [montant_honoraires, setHonoraires] = useState(0);
  const [nature_droits, setNatureDroits] = useState(0);
  const [nature_biens, setNatureBiens] = useState(0);
  const [nombre_parts_capital, setPartsCapital] = useState(0);
  const [nombre_parts_cedees, setPartsCedees] = useState(0);
  const [prix_cession, setPrixCession] = useState(0);
  const [montant_frais, setMontantFrais] = useState(0);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
      };

      if (setter === setDepartement) {
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { nature_droits: parseInt(nature_droits) });
        Object.assign(object["inputs"], { nature_biens: parseInt(nature_biens) });
        Object.assign(object["inputs"], { nombre_parts_capital: parseInt(nombre_parts_capital) });
        Object.assign(object["inputs"], { nombre_parts_cedees: parseFloat(nombre_parts_cedees) });
        Object.assign(object["inputs"], { prix_cession: parseFloat(prix_cession) });
        Object.assign(object["inputs"], { montant_frais: parseFloat(montant_frais) });
        object.departementid = parseInt(value);
      }

      if (setter === setHonoraires) {
        Object.assign(object["inputs"], { montant_honoraires: parseInt(value) });
        Object.assign(object["inputs"], { nature_droits: parseInt(nature_droits) });
        Object.assign(object["inputs"], { nature_biens: parseInt(nature_biens) });
        Object.assign(object["inputs"], { nombre_parts_capital: parseInt(nombre_parts_capital) });
        Object.assign(object["inputs"], { nombre_parts_cedees: parseFloat(nombre_parts_cedees) });
        Object.assign(object["inputs"], { prix_cession: parseFloat(prix_cession) });
        Object.assign(object["inputs"], { montant_frais: parseFloat(montant_frais) });
        object.departementid = parseInt(departement);
      }

      if (setter === setNatureDroits) {
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { nature_droits: parseInt(value) });
        Object.assign(object["inputs"], { nature_biens: parseInt(nature_biens) });
        Object.assign(object["inputs"], { nombre_parts_capital: parseInt(nombre_parts_capital) });
        Object.assign(object["inputs"], { nombre_parts_cedees: parseFloat(nombre_parts_cedees) });
        Object.assign(object["inputs"], { prix_cession: parseFloat(prix_cession) });
        Object.assign(object["inputs"], { montant_frais: parseFloat(montant_frais) });
        object.departementid = parseInt(departement);
      }

      if (setter === setNatureBiens) {
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { nature_droits: parseInt(nature_droits) });
        Object.assign(object["inputs"], { nature_biens: parseInt(value) });
        Object.assign(object["inputs"], { nombre_parts_capital: parseInt(nombre_parts_capital) });
        Object.assign(object["inputs"], { nombre_parts_cedees: parseFloat(nombre_parts_cedees) });
        Object.assign(object["inputs"], { prix_cession: parseFloat(prix_cession) });
        Object.assign(object["inputs"], { montant_frais: parseFloat(montant_frais) });
        object.departementid = parseInt(departement);
      }

      if (setter === setPartsCapital) {
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { nature_droits: parseInt(nature_droits) });
        Object.assign(object["inputs"], { nature_biens: parseInt(nature_biens) });
        Object.assign(object["inputs"], { nombre_parts_capital: parseInt(value) });
        Object.assign(object["inputs"], { nombre_parts_cedees: parseFloat(nombre_parts_cedees) });
        Object.assign(object["inputs"], { prix_cession: parseFloat(prix_cession) });
        Object.assign(object["inputs"], { montant_frais: parseFloat(montant_frais) });
        object.departementid = parseInt(departement);
      }

      if (setter === setPartsCedees) {
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { nature_droits: parseInt(nature_droits) });
        Object.assign(object["inputs"], { nature_biens: parseInt(nature_biens) });
        Object.assign(object["inputs"], { nombre_parts_capital: parseInt(nombre_parts_capital) });
        Object.assign(object["inputs"], { nombre_parts_cedees: parseFloat(value) });
        Object.assign(object["inputs"], { prix_cession: parseFloat(prix_cession) });
        Object.assign(object["inputs"], { montant_frais: parseFloat(montant_frais) });
        object.departementid = parseInt(departement);
      }

      if (setter === setPrixCession) {
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { nature_droits: parseInt(nature_droits) });
        Object.assign(object["inputs"], { nature_biens: parseInt(nature_biens) });
        Object.assign(object["inputs"], { nombre_parts_capital: parseInt(nombre_parts_capital) });
        Object.assign(object["inputs"], { nombre_parts_cedees: parseFloat(nombre_parts_cedees) });
        Object.assign(object["inputs"], { prix_cession: parseFloat(value) });
        Object.assign(object["inputs"], { montant_frais: parseFloat(montant_frais) });
        object.departementid = parseInt(departement);
      }

      if (setter === setMontantFrais) {
        Object.assign(object["inputs"], { montant_honoraires: parseInt(montant_honoraires) });
        Object.assign(object["inputs"], { nature_droits: parseInt(nature_droits) });
        Object.assign(object["inputs"], { nature_biens: parseInt(nature_biens) });
        Object.assign(object["inputs"], { nombre_parts_capital: parseInt(nombre_parts_capital) });
        Object.assign(object["inputs"], { nombre_parts_cedees: parseFloat(nombre_parts_cedees) });
        Object.assign(object["inputs"], { prix_cession: parseFloat(prix_cession) });
        Object.assign(object["inputs"], { montant_frais: parseFloat(value) });
        object.departementid = parseInt(departement);
      }

      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  const natureDroits = [
    {
      id: 0,
      name: "Parts sociales de société à prépondérance immobilière",
    },
    {
      id: 1,
      name: "Parts sociales représentatives d’APPORT EN NATURE (sauf société à prépondérance immobilière)",
    },
    {
      id: 2,
      name: "Parts sociales autres cas (Sociétés dont le capital n’est pas divisé en action - sauf société à prépondérance immobilière-)",
    },
    {
      id: 3,
      name: "Actions cotées en bourse, cession constatée par acte",
    },
    {
      id: 4,
      name: "Actions cotées en bourse, cession non constatée par acte",
    },
    {
      id: 5,
      name: "Actions non cotées en bourse, cession constatée ou non par acte",
    },
    {
      id: 6,
      name: "Obligations",
    },
  ];

  const natureBiens = [
    {
      id: 0,
      name: "Biens immobiliers",
    },
    {
      id: 1,
      name: "Fonds de commerce",
    },
    {
      id: 2,
      name: "Actions",
    },
  ];

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>
          Acte non tarifé donnant lieu à des honoraires dans les conditions de perception visées par l’article L. 444-2
          alinéa 3 du Code de commerce.
        </p>

        {props?.isParent === true && props.departements !== "" ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Quel est le montant de l’honoraire H.T. ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setHonoraires}
          />
        </div>

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Quelle est la nature des droits sociaux cédés ?
            <ToolTipPerso title={"La nature détermine la fiscalité à appliquer"} />
          </div>
          <SelectCommon
            items={natureDroits}
            defaultValue={0}
            nameField={"name"}
            optionValue={"id"}
            handleChange={handleChange}
            setter={setNatureDroits}
          />
        </div>

        {nature_droits == 1 ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>
              Quel est la nature des biens apportés en nature représentant les titres cédés ?
            </div>
            <SelectCommon
              items={natureBiens}
              defaultValue={0}
              nameField={"name"}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setNatureBiens}
            />
          </div>
        ) : (
          <></>
        )}

        {nature_droits == 2 ? (
          <>
            <div style={styles.cardRow}>
              <div style={styles.cardElemVerticalyCenter}>
                Quel est le nombre total de parts composant le capital social ?
              </div>
              <TextfieldNumber
                handleChange={handleChange}
                type={"number"}
                placeholder={"Indiquer le nombre"}
                setter={setPartsCapital}
              />
            </div>

            <div style={styles.cardRow}>
              <div style={styles.cardElemVerticalyCenter}>Quel est le nombre de parts cédées ?</div>
              <TextfieldNumber
                handleChange={handleChange}
                type={"number"}
                placeholder={"Indiquer le nombre"}
                setter={setPartsCedees}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Quel est le prix de cession ?
            <ToolTipPerso
              title={"Sur le prix exprimé dans l’acte augmenté des charges ou la déclaration estimative des parties"}
            />
          </div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le prix"}
            setter={setPrixCession}
          />
        </div>

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Quel est le montant estimé des frais à refacturer au client ?
          </div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setMontantFrais}
          />
        </div>
      </div>
    </div>
  );
}

export default Simulateur_83;

import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from 'react-redux';
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";
import moment from 'moment';

// Simulation de prorata de charges 
// Id simulateur 103

function Simulateur_103(props, simulateur, isParent) {
    const dispatch = useDispatch();

    const [departement, setDepartement] = useState(1)
    const [taxe_fonciere, setTaxe] = useState(0)
    const [charges_copropriete, setCopro] = useState(0)
    const [fonds_roulement, setRoulement] = useState(0)
    const [date_vente, setDate] = useState(new Date())

    const handleChange = async (setter, value) => {
        setter(value);
        setInRedux(setter, value)
    };

    const handleChangeRemarque = async (value) => {
        localStorage.setItem("remarque", value)
    }

    function setInRedux(setter, value) {
        if (props.isParent) {

            let object = {
                "simulateurid": props.simulateur.id,
                "inputs": {}
            };

            if (setter === setTaxe) {
                Object.assign(object['inputs'], { 'taxe_fonciere': parseInt(value) })
                Object.assign(object['inputs'], { 'charges_copropriete': parseInt(charges_copropriete) })
                Object.assign(object['inputs'], { 'fonds_roulement': parseInt(fonds_roulement) })
                if (date_vente !== undefined && value !== null) {
                    Object.assign(object['inputs'], { 'date_vente': parseInt(moment(date_vente.toString()).utc(true).format("x")) })
                }
                object.departementid = parseInt(departement)
            }

            if (setter === setCopro) {
                Object.assign(object['inputs'], { 'taxe_fonciere': parseInt(taxe_fonciere) })
                Object.assign(object['inputs'], { 'charges_copropriete': parseInt(value) })
                Object.assign(object['inputs'], { 'fonds_roulement': parseInt(fonds_roulement) })
                if (date_vente !== undefined && value !== null) {
                    Object.assign(object['inputs'], { 'date_vente': parseInt(moment(date_vente.toString()).utc(true).format("x")) })
                }
                object.departementid = parseInt(departement)
            }

            if (setter === setRoulement) {
                Object.assign(object['inputs'], { 'taxe_fonciere': parseInt(taxe_fonciere) })
                Object.assign(object['inputs'], { 'charges_copropriete': parseInt(charges_copropriete) })
                Object.assign(object['inputs'], { 'fonds_roulement': parseInt(value) })
                if (date_vente !== undefined && value !== null) {
                    Object.assign(object['inputs'], { 'date_vente': parseInt(moment(date_vente.toString()).utc(true).format("x")) })
                }
                object.departementid = parseInt(departement)
            }

            if (setter === setDate) {
                Object.assign(object['inputs'], { 'taxe_fonciere': parseInt(taxe_fonciere) })
                Object.assign(object['inputs'], { 'charges_copropriete': parseInt(charges_copropriete) })
                Object.assign(object['inputs'], { 'fonds_roulement': parseInt(fonds_roulement) })
                if (value !== undefined && value !== null) {
                    Object.assign(object['inputs'], { 'date_vente': parseInt(moment(value.toString()).utc(true).format("x")) })
                }
                object.departementid = parseInt(departement)
            }

            dispatch(setSimulateurObject(object));

        } else {

            // process child simu

        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

                <TextfieldRemarques handleChange={handleChangeRemarque} />

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le montant de la taxe foncière ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setTaxe}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le montant des charges de copropriété (trimestrielles) ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setCopro}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Quel est le besoin en fonds de roulement ?
                    </div>
                    <TextfieldNumber
                        handleChange={handleChange}
                        type={"number"}
                        placeholder={"Indiquer le montant"}
                        setter={setRoulement}
                    />
                </div>

                <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>
                        Indiquer la date de la vente
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            inputFormat="DD/MM/YYYY"
                            value={date_vente}
                            onChange={((val) => {
                                handleChange(setDate, val)
                            })}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>

            </div>

        </div>
    )
}

export default Simulateur_103;

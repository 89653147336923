import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import ButtonYesno from "../../../components/buttons/button.yesno";
import DmtgMain from "../../dmtg/DmtgMain";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";
import { ToolTipPerso } from "../../../components/selects/tooltip_perso";
import { Typography } from "@mui/material";

// Déclarations de successions
// Id simulateur 52, A2-12
// simulateurs associés : dmtg succession

function Simulateur_52(props, simulateur, isParent, departements) {
  const dispatch = useDispatch();

  const currentIdSimu = 52;
  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [simulateurForDMTG, setSimulateurForDMTG] = useState({
    id: 55,
    name: "DMTG - Succession 1 à 9 personnes",
  });

  const [departement, setDepartement] = useState(0);
  const [montant_succession, setSuccession] = useState(0);
  const [DMTG, setDMTG] = useState(0);
  const [responseDMTG, setResponseDMTG] = useState(null);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  const texteAvecSautsDeLigne =
    "Ce champ sert au calcul des émoluments. Il doit comprendre : l’actif brut total (en ce compris s’il y a communauté, participation ou société d’acquêts, les biens qui en dépendent. Article A444-113, N°8 du Tableau 5), le montant total des donations rapportables (pour leur valeur de rapport au jour du décès) et retirer le passif de succession (au sens fiscal).";

  const lignes = texteAvecSautsDeLigne.split("\n");

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
        DMTG: [],
      };

      if (setter === setDepartement) {
        Object.assign(object["inputs"], {
          montant_succession: parseInt(montant_succession),
        });
        Object.assign(object["inputs"], { DMTG: parseInt(DMTG) });
        object.DMTG = responseDMTG;
        object.departementid = parseInt(value);
      }

      if (setter === setSuccession) {
        Object.assign(object["inputs"], { montant_succession: parseInt(value) });
        Object.assign(object["inputs"], { DMTG: parseInt(DMTG) });
        object.DMTG = responseDMTG;
        object.departementid = parseInt(departement);
      }

      if (setter === setDMTG) {
        if (value === 0) {
          setResponseDMTG([]);
          Object.assign(object["inputs"], { montant_succession: parseInt(montant_succession) });
          Object.assign(object["inputs"], { DMTG: parseInt(value) });
          object.DMTG = [];
          object.departementid = parseInt(departement);
        } else {
          Object.assign(object["inputs"], { montant_succession: parseInt(montant_succession) });
          Object.assign(object["inputs"], { DMTG: parseInt(value) });
          object.DMTG = responseDMTG;
          object.departementid = parseInt(departement);
        }
      }

      if (setter === setResponseDMTG) {
        Object.assign(object["inputs"], { montant_succession: parseInt(montant_succession) });
        Object.assign(object["inputs"], { DMTG: parseInt(DMTG) });
        Object.assign(object["inputs"], { result: 1 });
        object.DMTG = value;
        object.departementid = parseInt(departement);
      }

      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>Acte tarifé au titre du Tableau 5, n° 8 (Art A444-63)</p>

        {props?.isParent === true && props.departements !== "" ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Montant de la succession :
            <ToolTipPerso
              title={
                <Typography style={{ fontSize: 14 }}>
                  {lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {ligne}
                    </React.Fragment>
                  ))}
                </Typography>
              }
            />
          </div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setSuccession}
          />
        </div>

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Souhaitez-vous faire le calcul des DMTG ?</div>
          <div>
            <ButtonYesno handleChange={handleChange} setter={setDMTG} fieldUndefined={false} defaultValue={0} />
          </div>
        </div>

        {DMTG ? (
          <DmtgMain
            callSinceSimu={true}
            simulateur={simulateurForDMTG}
            setter={setResponseDMTG}
            handleChange={handleChange}
            calculateDMTGState={props.calculateDMTGState}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Simulateur_52;

import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import ButtonYesno from "../../../components/buttons/button.yesno";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";
import TextfieldNumberSPF from "../../../components/textfields/textfield.number.spf";
import {useSnackbar} from "notistack";

// Constitution de servitude(s) dans un acte de vente (si disposition indépendante)
// simulateur Id 34

function Simulateur_34(props, simulateur, isParent, departements) {
  const dispatch = useDispatch();

  const currentIdSimu = 34;
  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [departement, setDepartement] = useState(0);
  const [nombre_servitude, setNbServitude] = useState(0);
  const [prix_acte, setPrixActe] = useState(0);
  const [prixServitude, setPrixServitude] = useState([]);
  const [valeur_servitude, setValeurServitude] = useState(0);
  const [facturation_forfait, setForfait] = useState(0);
  const [nombre_forfait, setNbForfait] = useState(0);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
      setInRedux(setter, value);
    }
    if (setter === setNbServitude && value > 10) {
      setNbServitude(10);
      setInRedux(setNbServitude, 10);
      enqueueSnackbar('La quantité maximale de servitudes est de 10', {
        variant: 'warning'
      });
    } else {
      setter(value);
      setInRedux(setter, value);
    }
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  useEffect(async () => {
    if (nombre_servitude >= 1) {
      if (prixServitude.length < nombre_servitude) {
        let handle = [];
        for (let i = 0; i < prixServitude.length; i++) {
          handle[i] = prixServitude[i];
        }
        for (let i = prixServitude.length; i < nombre_servitude; i++) {
          handle[i] = {};
        }
        await handleChange(setPrixServitude, handle);
      } else if (nombre_servitude < prixServitude.length) {
        //console.log("SPLICE CASE")
        prixServitude.splice(parseInt(nombre_servitude));
        await handleChange(setPrixServitude, prixServitude);
      } else if (prixServitude.length === 0) {
        await handleChange(setPrixServitude, [{}]);
      }
    } else {
      await handleChange(setPrixServitude, []);
    }
  }, [nombre_servitude]);

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
        dependances: null,
      };

      if (setter === setDepartement) {
        Object.assign(object["inputs"], { nombre_servitude: parseInt(nombre_servitude) });
        Object.assign(object["inputs"], { prix_acte: parseInt(prix_acte) });
        Object.assign(object["inputs"], { valeur_servitude: parseInt(valeur_servitude) });
        Object.assign(object["inputs"], { facturation_forfait: parseInt(facturation_forfait) });
        Object.assign(object["inputs"], { nombre_forfait: parseInt(nombre_forfait) });
        object.dependances = prixServitude;
        object.departementid = parseInt(value);
      }

      if (setter === setNbServitude) {
        Object.assign(object["inputs"], { nombre_servitude: parseInt(value) });
        Object.assign(object["inputs"], { prix_acte: parseInt(prix_acte) });
        Object.assign(object["inputs"], { valeur_servitude: parseInt(valeur_servitude) });
        Object.assign(object["inputs"], { facturation_forfait: parseInt(facturation_forfait) });
        Object.assign(object["inputs"], { nombre_forfait: parseInt(nombre_forfait) });
        object.dependances = prixServitude;
        object.departementid = parseInt(departement);
      }

      if (setter === setPrixActe) {
        Object.assign(object["inputs"], { nombre_servitude: parseInt(nombre_servitude) });
        Object.assign(object["inputs"], { prix_acte: parseInt(value) });
        Object.assign(object["inputs"], { valeur_servitude: parseInt(valeur_servitude) });
        Object.assign(object["inputs"], { facturation_forfait: parseInt(facturation_forfait) });
        Object.assign(object["inputs"], { nombre_forfait: parseInt(nombre_forfait) });
        object.dependances = prixServitude;
        object.departementid = parseInt(departement);
      }

      if (setter === setPrixServitude) {
        Object.assign(object["inputs"], { nombre_servitude: parseInt(nombre_servitude) });
        Object.assign(object["inputs"], { prix_acte: parseInt(prix_acte) });
        Object.assign(object["inputs"], { valeur_servitude: parseInt(valeur_servitude) });
        Object.assign(object["inputs"], { facturation_forfait: parseInt(facturation_forfait) });
        Object.assign(object["inputs"], { nombre_forfait: parseInt(nombre_forfait) });
        object.dependances = value;
        object.departementid = parseInt(departement);
      }

      if (setter === setValeurServitude) {
        Object.assign(object["inputs"], { nombre_servitude: parseInt(nombre_servitude) });
        Object.assign(object["inputs"], { prix_acte: parseInt(prix_acte) });
        Object.assign(object["inputs"], { valeur_servitude: parseInt(value) });
        Object.assign(object["inputs"], { facturation_forfait: parseInt(facturation_forfait) });
        Object.assign(object["inputs"], { nombre_forfait: parseInt(nombre_forfait) });
        object.dependances = prixServitude;
        object.departementid = parseInt(departement);
      }

      if (setter === setForfait) {
        if (value === 0) {
          handleChange(setNbForfait, 0);
          Object.assign(object["inputs"], { nombre_servitude: parseInt(nombre_servitude) });
          Object.assign(object["inputs"], { prix_acte: parseInt(prix_acte) });
          Object.assign(object["inputs"], { valeur_servitude: parseInt(valeur_servitude) });
          Object.assign(object["inputs"], { facturation_forfait: parseInt(value) });
          Object.assign(object["inputs"], { nombre_forfait: 0 });
          object.dependances = prixServitude;
          object.departementid = parseInt(departement);
        } else {
          Object.assign(object["inputs"], { nombre_servitude: parseInt(nombre_servitude) });
          Object.assign(object["inputs"], { prix_acte: parseInt(prix_acte) });
          Object.assign(object["inputs"], { valeur_servitude: parseInt(valeur_servitude) });
          Object.assign(object["inputs"], { facturation_forfait: parseInt(value) });
          Object.assign(object["inputs"], { nombre_forfait: parseInt(nombre_forfait) });
          object.dependances = prixServitude;
          object.departementid = parseInt(departement);
        }
      }

      if (setter === setNbForfait) {
        Object.assign(object["inputs"], { nombre_servitude: parseInt(nombre_servitude) });
        Object.assign(object["inputs"], { prix_acte: parseInt(prix_acte) });
        Object.assign(object["inputs"], { valeur_servitude: parseInt(valeur_servitude) });
        Object.assign(object["inputs"], { facturation_forfait: parseInt(facturation_forfait) });
        Object.assign(object["inputs"], { nombre_forfait: parseInt(value) });
        object.dependances = prixServitude;
        object.departementid = parseInt(departement);
      }

      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  function functionSetPrixServitude(index, value) {
    if (prixServitude.length >= 1) {
      let formatedData = {
        prix_servitude: parseInt(value),
      };
      prixServitude[index] = formatedData;
      setInRedux(setPrixServitude, prixServitude);
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>
          Acte tarifé au titre du Tableau 5, n°92 et 93 (Art A444-115). Attention, aucune perception d’émolument n’est
          due s’il s’agit d’un rappel de servitude. Si disposition dépendante, prévoir minimum 15 euros de CSI de
          publication supplémentaire, par constitution de servitude.
        </p>

        {props?.isParent === true && props.departements !== "" ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Combien y a-t-il de servitudes à créer ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le nombre"}
            setter={setNbServitude}
            max={"10"}
          />
        </div>

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Y a-t-il un prix exprimé dans l’acte ?</div>
          <div>
            <ButtonYesno handleChange={handleChange} setter={setPrixActe} fieldUndefined={false} defaultValue={0} />
          </div>
        </div>
        {parseInt(prix_acte) === 1 ? (
          prixServitude.length >= 1 ? (
            prixServitude.map((valeur, index) => {
              return (
                <div style={styles.cardRowChild}>
                  <div style={styles.cardRow}>
                    <div style={styles.cardElemVerticalyCenter}>Indiquer le prix de la servitude n° {index + 1} :</div>
                    <TextfieldNumberSPF
                      index={index}
                      type={"number"}
                      placeholder={"Indiquer la valeur"}
                      functionSetValeurs={functionSetPrixServitude}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )
        ) : (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>
              Quelle est la valeur estimée de la servitude ou des servitudes à créer ?
            </div>
            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer la valeur"}
              setter={setValeurServitude}
            />
          </div>
        )}

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Est-il prévu la facturation de plusieurs forfaits de formalité (Article 194 du Tableau 5) ?
          </div>
          <div>
            <ButtonYesno handleChange={handleChange} setter={setForfait} fieldUndefined={false} defaultValue={0} />
          </div>
        </div>
        {parseInt(facturation_forfait) === 1 ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>Indiquer le nombre :</div>
            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer le nombre"}
              setter={setNbForfait}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Simulateur_34;

import Colors from "../../../useclass/colors";
import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import Simulateur96 from "../simulateur.A2.18/simulateur.A2.18";
import Simulateur97 from "../simulateur.A2.19/simulateur.A2.19";
import ButtonYesno from "../../../components/buttons/button.yesno.js";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import SelectDepartement from "../../../components/selects/select.departement";
import Simulateur92 from "../simulateur.A1.12PRO/simulateur.A1.12PRO";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Rétrocession et substitution par la SAFER
// simulateur id 9

function Simulateur_A1_5bis(props, simulateur, isParent, departements) {
  const dispatch = useDispatch();

  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [departement, setDepartement] = useState(0);
  const [promesse, setPromesse] = useState(0);
  const [debour, setDebour] = useState(2);
  const [pretHypo, setPretHypo] = useState(0);
  const [montantPretHp, setMontantPretHp] = useState(0);
  const [sansGarantieHypo, setSansGarantieHypo] = useState(0);
  const [avecGarantieHypo, setAvecGarantieHypo] = useState(0);
  const [pacte_preference, setPactePreference] = useState(0);
  const [action_resolutoire, setActionResolutoire] = useState(0);
  const [simu96, setSimu96] = useState(null);
  const [simuA112PRO, setSimuA112PRO] = useState(null);
  const currentIdSimu = 9;
  const [simu97, setSimu97] = useState(null);
  const [montantRetrocession, setMontantRetrocession] = useState(0);
  const [montantPromesse, setMontantPromesse] = useState(0);
  const [montantUrbanisme, setMontantUrbanisme] = useState(0);
  const [urbanisme, setUrbanisme] = useState(0);
  const [dependances, setDependancesState] = useState([]);
  const [dep1, setDep1] = useState({});

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  useEffect(() => {
    if (simu96 !== undefined && simu96 !== null) setDependances(simu96);
  }, [simu96]);

  useEffect(() => {
    if (simuA112PRO !== undefined && simuA112PRO !== null) setDependances(simuA112PRO);
  }, [simuA112PRO]);

  useEffect(() => {
    if (simu97 !== undefined && simu97 !== null) setDependances(simu97);
  }, [simu97]);

  function setDependances(simu) {
    setInRedux("dependance", simu);
  }

  function setInRedux(setter, value) {
    let object = {
      simulateurid: currentIdSimu,
      departementid: 0,
      inputs: {},
      dependances: [],
    };

    if (setter === setPretHypo) {
      if (value === 0) {
        setSimuA112PRO(null);
        setSimu96(null);
        setSimu97(null);
        setDependancesState([]);
        Object.assign(object["inputs"], { pacte_preference: parseInt(pacte_preference) });
        Object.assign(object["inputs"], { action_resolutoire: parseInt(action_resolutoire) });
        Object.assign(object["inputs"], { montant_retrocession: parseInt(montantRetrocession) });
        Object.assign(object["inputs"], { montant_promesse: parseInt(montantPromesse) });
        Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
        Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
        Object.assign(object["inputs"], { promesse: parseInt(promesse) });
        object.dependances = [];
        object.departementid = parseInt(departement);
      } else {
        Object.assign(object["inputs"], { pacte_preference: parseInt(pacte_preference) });
        Object.assign(object["inputs"], { action_resolutoire: parseInt(action_resolutoire) });
        Object.assign(object["inputs"], { montant_retrocession: parseInt(montantRetrocession) });
        Object.assign(object["inputs"], { montant_promesse: parseInt(montantPromesse) });
        Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
        Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
        Object.assign(object["inputs"], { promesse: parseInt(promesse) });
        object.dependances = dependances;
        object.departementid = parseInt(departement);
      }
    }

    if (setter === setSansGarantieHypo) {
      if (value === 0) {
        const index = dependances.findIndex((obj) => obj.simulateurid === 96);
        dependances?.splice(index, 1);
        setSimu96(null);
      }
      Object.assign(object["inputs"], { pacte_preference: parseInt(pacte_preference) });
      Object.assign(object["inputs"], { action_resolutoire: parseInt(action_resolutoire) });
      Object.assign(object["inputs"], { montant_retrocession: parseInt(montantRetrocession) });
      Object.assign(object["inputs"], { montant_promesse: parseInt(montantPromesse) });
      Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
      Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
      Object.assign(object["inputs"], { promesse: parseInt(promesse) });
      object.dependances = dependances;
      object.departementid = parseInt(departement);
    }

    if (setter === setAvecGarantieHypo) {
      if (value === 0) {
        const index = dependances.findIndex((obj) => obj.simulateurid === 97);
        dependances?.splice(index, 1);
        setSimu97(null);
      }
      Object.assign(object["inputs"], { pacte_preference: parseInt(pacte_preference) });
      Object.assign(object["inputs"], { action_resolutoire: parseInt(action_resolutoire) });
      Object.assign(object["inputs"], { montant_retrocession: parseInt(montantRetrocession) });
      Object.assign(object["inputs"], { montant_promesse: parseInt(montantPromesse) });
      Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
      Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
      Object.assign(object["inputs"], { promesse: parseInt(promesse) });
      object.dependances = dependances;
      object.departementid = parseInt(departement);
    }

    if (setter === "dependance") {
      if (dependances.length > 0) object.dependances = dependances;

      if (value !== undefined) {
        let validator = false;

        for (let i = 0; i < object["dependances"].length; i++) {
          if (object["dependances"][i]?.simulateurid === value.simulateurid) {
            object.dependances[i] = value;
            validator = true;
          }
        }

        if (validator === false) {
          object.dependances.push(value);
        }

        setDependancesState(object.dependances);
        Object.assign(object["inputs"], { pacte_preference: parseInt(pacte_preference) });
        Object.assign(object["inputs"], { action_resolutoire: parseInt(action_resolutoire) });
        Object.assign(object["inputs"], { montant_retrocession: parseInt(montantRetrocession) });
        Object.assign(object["inputs"], { montant_promesse: parseInt(montantPromesse) });
        Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
        Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
        Object.assign(object["inputs"], { promesse: parseInt(promesse) });
        object.departementid = parseInt(departement);
      }
    }

    if (setter === setMontantRetrocession) {
      Object.assign(object["inputs"], { montant_retrocession: parseInt(value) });
      Object.assign(object["inputs"], { pacte_preference: parseInt(pacte_preference) });
      Object.assign(object["inputs"], { action_resolutoire: parseInt(action_resolutoire) });
      Object.assign(object["inputs"], { montant_promesse: parseInt(montantPromesse) });
      Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
      Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
      Object.assign(object["inputs"], { promesse: parseInt(promesse) });
      object.dependances = dependances;
      object.departementid = parseInt(departement);
    }

    if (setter === setPactePreference) {
      Object.assign(object["inputs"], { pacte_preference: parseInt(value) });
      Object.assign(object["inputs"], { action_resolutoire: parseInt(action_resolutoire) });
      Object.assign(object["inputs"], { montant_retrocession: parseInt(montantRetrocession) });
      Object.assign(object["inputs"], { montant_promesse: parseInt(montantPromesse) });
      Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
      Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
      Object.assign(object["inputs"], { promesse: parseInt(promesse) });
      object.dependances = dependances;
      object.departementid = parseInt(departement);
    }

    if (setter === setActionResolutoire) {
      Object.assign(object["inputs"], { pacte_preference: parseInt(pacte_preference) });
      Object.assign(object["inputs"], { action_resolutoire: parseInt(value) });
      Object.assign(object["inputs"], { montant_retrocession: parseInt(montantRetrocession) });
      Object.assign(object["inputs"], { montant_promesse: parseInt(montantPromesse) });
      Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
      Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
      Object.assign(object["inputs"], { promesse: parseInt(promesse) });
      object.dependances = dependances;
      object.departementid = parseInt(departement);
    }

    if (setter === setMontantPromesse) {
      Object.assign(object["inputs"], { montant_promesse: parseInt(value) });
      Object.assign(object["inputs"], { pacte_preference: parseInt(pacte_preference) });
      Object.assign(object["inputs"], { action_resolutoire: parseInt(action_resolutoire) });
      Object.assign(object["inputs"], { montant_retrocession: parseInt(montantRetrocession) });
      Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
      Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
      Object.assign(object["inputs"], { promesse: parseInt(promesse) });
      object.dependances = dependances;
      object.departementid = parseInt(departement);
    }

    if (setter === setMontantUrbanisme) {
      Object.assign(object["inputs"], { montant_urbanisme: parseInt(value) });
      Object.assign(object["inputs"], { pacte_preference: parseInt(pacte_preference) });
      Object.assign(object["inputs"], { action_resolutoire: parseInt(action_resolutoire) });
      Object.assign(object["inputs"], { montant_promesse: parseInt(montantPromesse) });
      Object.assign(object["inputs"], { montant_retrocession: parseInt(montantRetrocession) });
      Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
      Object.assign(object["inputs"], { promesse: parseInt(promesse) });
      object.dependances = dependances;
      object.departementid = parseInt(departement);
    }

    if (setter === setDepartement) {
      Object.assign(object["inputs"], { pacte_preference: parseInt(pacte_preference) });
      Object.assign(object["inputs"], { action_resolutoire: parseInt(action_resolutoire) });
      Object.assign(object["inputs"], { montant_promesse: parseInt(montantPromesse) });
      Object.assign(object["inputs"], { montant_retrocession: parseInt(montantRetrocession) });
      Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
      Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
      Object.assign(object["inputs"], { promesse: parseInt(promesse) });
      object.dependances = dependances;
      object.departementid = parseInt(value);
    }

    if (setter === setUrbanisme) {
      Object.assign(object["inputs"], { pacte_preference: parseInt(pacte_preference) });
      Object.assign(object["inputs"], { action_resolutoire: parseInt(action_resolutoire) });
      Object.assign(object["inputs"], { montant_promesse: parseInt(montantPromesse) });
      Object.assign(object["inputs"], { montant_retrocession: parseInt(montantRetrocession) });
      Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
      Object.assign(object["inputs"], { urbanisme: parseInt(value) });
      Object.assign(object["inputs"], { promesse: parseInt(promesse) });
      object.dependances = dependances;
      object.departementid = parseInt(departement);
    }

    if (setter === setPromesse) {
      Object.assign(object["inputs"], { pacte_preference: parseInt(pacte_preference) });
      Object.assign(object["inputs"], { action_resolutoire: parseInt(action_resolutoire) });
      Object.assign(object["inputs"], { montant_promesse: parseInt(montantPromesse) });
      Object.assign(object["inputs"], { montant_retrocession: parseInt(montantRetrocession) });
      Object.assign(object["inputs"], { montant_urbanisme: parseInt(montantUrbanisme) });
      Object.assign(object["inputs"], { urbanisme: parseInt(urbanisme) });
      Object.assign(object["inputs"], { promesse: parseInt(value) });
      object.dependances = dependances;
      object.departementid = parseInt(departement);
    }

    if (props.isParent) {
      dispatch(setSimulateurObject(object));
    } else {
    }
  }

  const depsTaxeIleFrance = [75, 77, 78, 91, 92, 93, 94, 95];
  const depsZrr = [14, 38, 51, 71];

  const itemsTypeSale = ["Ventes d'immeubles (régime de la copropriété) ou locaux bâtis affectés à tous usages"];
  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>
          Pour la vente, acte tarifé au titre du Tableau 5, n° 54 (Art A444-91). Prêt aux professionnels associé acte
          tarifé au titre du Tableau 5, n° 128 (Art A444-139). Cautionnement associé acte tarifé au titre du Tableau 5,
          n° 111 (Art A444-127).{" "}
        </p>

        {props?.isParent === true && props.departements !== "" ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Montant de la rétrocession ou substitution :</div>

          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setMontantRetrocession}
          />
        </div>

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Y’a-t-il un pacte de préférence ?</div>
          <div>
            <ButtonYesno
              handleChange={handleChange}
              setter={setPactePreference}
              fieldUndefined={false}
              defaultValue={0}
            />
          </div>
        </div>

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Y’a-t-il une action résolutoire ou un droit de délaissement ?
          </div>
          <div>
            <ButtonYesno
              handleChange={handleChange}
              setter={setActionResolutoire}
              fieldUndefined={false}
              defaultValue={0}
            />
          </div>
        </div>

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Y a-t-il une promesse de vente reçue sous forme authentique ?
          </div>
          <div>
            <ButtonYesno handleChange={handleChange} setter={setPromesse} fieldUndefined={false} defaultValue={0} />
          </div>
        </div>
        {parseInt(promesse) === 1 ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>
              Indiquer le montant HT des honoraires de rédaction (s'il y a lieu) :
            </div>
            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer le montant"}
              setter={setMontantPromesse}
            />
          </div>
        ) : (
          <></>
        )}

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Y a-t-il un urbanisme demandé à un tiers, ou d'autres débours à prévoir ?
          </div>
          <div>
            <ButtonYesno handleChange={handleChange} setter={setUrbanisme} fieldUndefined={true} defaultValue={2} />
          </div>
        </div>

        {parseInt(urbanisme) === 1 ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>Montant de l'urbanisme :</div>
            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer le montant"}
              setter={setMontantUrbanisme}
            />
          </div>
        ) : (
          <></>
        )}

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Y'a-t-il un prêt hypothécaire ?</div>
          <div>
            <ButtonYesno handleChange={handleChange} setter={setPretHypo} fieldUndefined={false} defaultValue={0} />
          </div>
        </div>

        {parseInt(pretHypo) === 1 ? (
          <>
            <Simulateur92
              // simulateur={props.simulateur}
              // isParent={false}
              // departements={departements}
              contentDep={setDep1}
              simulateur={props.simulateur}
              isParent={false}
              departementId={departement}
              dependances={setSimuA112PRO}
            />

            <div style={styles.cardRow}>
              <div style={styles.cardElemVerticalyCenter}>Y'a-t-il un cautionnement sans garantie hypothécaire ?</div>
              <div>
                <ButtonYesno
                  handleChange={handleChange}
                  setter={setSansGarantieHypo}
                  fieldUndefined={false}
                  defaultValue={0}
                />
              </div>
            </div>

            {parseInt(sansGarantieHypo) === 1 ? (
              <>
                <Simulateur96
                  simulateur={props.simulateur}
                  isParent={false}
                  departementId={departement}
                  dependances={setSimu96}
                />
              </>
            ) : (
              <></>
            )}

            <div style={styles.cardRow}>
              <div style={styles.cardElemVerticalyCenter}>Y'a-t-il un cautionnement avec garantie hypothécaire ?</div>
              <div>
                <ButtonYesno
                  handleChange={handleChange}
                  setter={setAvecGarantieHypo}
                  fieldUndefined={false}
                  defaultValue={0}
                />
              </div>
            </div>

            {parseInt(avecGarantieHypo) === 1 ? (
              <>
                <Simulateur97
                  simulateur={props.simulateur}
                  isParent={false}
                  departementId={departement}
                  dependances={setSimu97}
                />
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Simulateur_A1_5bis;

import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import ButtonYesno from "../../../components/buttons/button.yesno";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";
import { ToolTipPerso } from "../../../components/selects/tooltip_perso";
import { Typography } from "@mui/material";

// Changement de régime matrimonial avec apport ou biens déclarés
// Id simulateur 76

function Simulateur_76(props, simulateur, isParent, departements) {
  const dispatch = useDispatch();

  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [departement, setDepartement] = useState(0);
  const [valeur_biens_declares, setBiens] = useState(0);
  const [formalites_publication, setPubli] = useState(0);
  const [valeur_biens_immo, setBiensImmo] = useState(0);
  const [nombre_spf, setSPF] = useState(0);
  const [nombre_enfants, setEnfants] = useState(0);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
      };

      if (setter === setDepartement) {
        Object.assign(object["inputs"], { valeur_biens_declares: parseInt(valeur_biens_declares) });
        Object.assign(object["inputs"], { formalites_publication: parseInt(formalites_publication) });
        Object.assign(object["inputs"], { valeur_biens_immo: parseInt(valeur_biens_immo) });
        Object.assign(object["inputs"], { nombre_spf: parseInt(nombre_spf) });
        Object.assign(object["inputs"], { nombre_enfants: parseInt(nombre_enfants) });
        object.departementid = parseInt(value);
      }

      if (setter === setBiens) {
        Object.assign(object["inputs"], { valeur_biens_declares: parseInt(value) });
        Object.assign(object["inputs"], { formalites_publication: parseInt(formalites_publication) });
        Object.assign(object["inputs"], { valeur_biens_immo: parseInt(valeur_biens_immo) });
        Object.assign(object["inputs"], { nombre_spf: parseInt(nombre_spf) });
        Object.assign(object["inputs"], { nombre_enfants: parseInt(nombre_enfants) });
        object.departementid = parseInt(departement);
      }

      if (setter === setPubli) {
        Object.assign(object["inputs"], { valeur_biens_declares: parseInt(valeur_biens_declares) });
        Object.assign(object["inputs"], { formalites_publication: parseInt(value) });
        Object.assign(object["inputs"], { valeur_biens_immo: parseInt(valeur_biens_immo) });
        Object.assign(object["inputs"], { nombre_spf: parseInt(nombre_spf) });
        Object.assign(object["inputs"], { nombre_enfants: parseInt(nombre_enfants) });
        object.departementid = parseInt(departement);
      }

      if (setter === setBiensImmo) {
        Object.assign(object["inputs"], { valeur_biens_declares: parseInt(valeur_biens_declares) });
        Object.assign(object["inputs"], { formalites_publication: parseInt(formalites_publication) });
        Object.assign(object["inputs"], { valeur_biens_immo: parseInt(value) });
        Object.assign(object["inputs"], { nombre_spf: parseInt(nombre_spf) });
        Object.assign(object["inputs"], { nombre_enfants: parseInt(nombre_enfants) });
        object.departementid = parseInt(departement);
      }

      if (setter === setSPF) {
        Object.assign(object["inputs"], { valeur_biens_declares: parseInt(valeur_biens_declares) });
        Object.assign(object["inputs"], { formalites_publication: parseInt(formalites_publication) });
        Object.assign(object["inputs"], { valeur_biens_immo: parseInt(valeur_biens_immo) });
        Object.assign(object["inputs"], { nombre_spf: parseInt(value) });
        Object.assign(object["inputs"], { nombre_enfants: parseInt(nombre_enfants) });
        object.departementid = parseInt(departement);
      }

      if (setter === setEnfants) {
        Object.assign(object["inputs"], { valeur_biens_declares: parseInt(valeur_biens_declares) });
        Object.assign(object["inputs"], { formalites_publication: parseInt(formalites_publication) });
        Object.assign(object["inputs"], { valeur_biens_immo: parseInt(valeur_biens_immo) });
        Object.assign(object["inputs"], { nombre_spf: parseInt(nombre_spf) });
        Object.assign(object["inputs"], { nombre_enfants: parseInt(value) });
        object.departementid = parseInt(departement);
      }

      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  const texteAvecSautsDeLigne =
    "Si bien propre ou personnel à l’époux apporteur, indiquer 50 % de la valeur du bien \n \n Si bien détenu en indivision à hauteur de 50 % par chaque époux apporteur, Néant \n \n Si bien détenu en indivision dans des proportions différentes par chaque époux apporteur, indiquer en valeur la différence entre 50 % et la quote-part minoritaire, soit le droit conféré à l’autre époux par cet apport";

  const lignes = texteAvecSautsDeLigne.split("\n");

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>
          Acte tarifé au titre du Tableau 5, n° 41 (Art A444-82). Dans l’hypothèse où aucune formalité de publication au
          service de la publicité foncière n’est requise, la provision sur frais calculée ne prévoit pas les éventuels
          frais de l’acte de dépôt pour constater l’absence d’opposition.{" "}
        </p>

        {props?.isParent === true && props.departements !== "" ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Quelle est la valeur des biens déclarés et/ou apportés ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer la valeur"}
            setter={setBiens}
          />
        </div>

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Est-il prévu des formalités de publication au service de la publicité foncière ?
          </div>
          <div>
            <ButtonYesno handleChange={handleChange} setter={setPubli} fieldUndefined={false} defaultValue={0} />
          </div>
        </div>

        {parseInt(formalites_publication) === 1 ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>
              Indiquer la valeur des biens immobiliers concernés{" "}
              <ToolTipPerso
                title={
                  <Typography style={{ fontSize: 14 }}>
                    {lignes.map((ligne, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && <br />}
                        {ligne}
                      </React.Fragment>
                    ))}
                  </Typography>
                }
              />
            </div>

            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer la valeur"}
              setter={setBiensImmo}
            />
          </div>
        ) : (
          <></>
        )}

        {parseInt(formalites_publication) === 1 ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>Indiquer le nombre de SPF concernés</div>
            <TextfieldNumber
              handleChange={handleChange}
              type={"number"}
              placeholder={"Indiquer le nombre"}
              setter={setSPF}
            />
          </div>
        ) : (
          <></>
        )}

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Combien y a-t-il d’enfants(s) majeur(s) ?</div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le nombre"}
            setter={setEnfants}
          />
        </div>
      </div>
    </div>
  );
}

export default Simulateur_76;

import React, { useEffect, useState } from "react";
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import Button12 from "../../../components/buttons/button.12";
import Donations from "../../../components/dmtg/donations";
import { setDMTGResultsObject } from "../../../actions/dmtg_results.action";
import { useDispatch } from "react-redux";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

function Simulateur_Donations(props, isParent) {
  const dispatch = useDispatch();
  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [donateurs, setDonateurs] = useState(0);
  const [donataires, setDonataires] = useState(1);

  const [allDonatairesList, setAllDonatairesList] = useState([]);
  const [results, setResults] = useState([]);

  // Hook prenant pour callback le state "numberOfComponentRequired". A chacune des modifications apportées à "numberOfComponentRequired",
  // ce hook sera activé et génèrera le state "results" adapté pour stocker toute la data des components enfants.
  useEffect(() => {
    if (props.callSinceSimu) {
      handleChange(setDonateurs, props.donateurs);
    }
  }, [props.donateurs]);

  useEffect(async () => {
    //console.log("donat" + donateurs)
    if (donateurs === 1) {
      //console.log("donateurs 1")
      if (results.length == 2) {
        allDonatairesList.splice(1);
        results.splice(1);
        setInRedux(results);
      } else {
        await setResults([{}]);
        await setAllDonatairesList([{}]);
      }

      //console.log(results)
    } else if (donateurs === 2) {
      await setResults([results[0], {}]);
      await setAllDonatairesList([results[0], {}]);
      //console.log("donateurs 2")
    }
    //console.log("Tableau results")
    //console.log(results)
  }, [donateurs]);

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  // Function permettant d'avoir la main sur la modification du nombre de components demandé et d'y ajouter une limite
  // pour éviter la génération abusive.
  const handleChange = async (setter, value) => {
    setter(value);
  };
  const handleChangeWithRestriction = async (setter, value) => {
    // Limite à adapter --v
    if (value < 10) setter(value);
    else setter(9);
  };

  // Fonction call depuis le component enfant qui set la key du state "results" modifiée.
  function FunctionSetResults(key, value) {
    results[key] = value;
    setInRedux(results);
  }

  function FunctionSetAllDonatairesList(key, value) {
    allDonatairesList[key] = value;
    //console.log("iciii")
    //console.log(value)
  }

  function setInRedux(results) {
    let object = { results: results };
    Object.assign(object["results"], results);
    //console.log(object)
    dispatch(setDMTGResultsObject(object));
  }

  //props?.isParent === true
  return (
    <div style={styles.container}>
      <div style={props.callSinceSimu ? styles.cardDMTG : styles.card}>
        {props.callSinceSimu ? (
          <></>
        ) : (
          <>
            <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

            <TextfieldRemarques handleChange={handleChangeRemarque} />

            <div style={styles.cardRow}>
              <div style={styles.cardElemVerticalyCenter}>Combien y a t'il de donateurs ?</div>
              <Button12 handleChange={handleChange} setter={setDonateurs} defaultValue={1} />
            </div>
          </>
        )}

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Combien de donataires sont concernés ?</div>
          <TextfieldNumber
            handleChange={handleChangeWithRestriction}
            type={"number"}
            defaultValue={1}
            placeholder={"Nombre de donataires"}
            setter={setDonataires}
          />
        </div>

        <div style={styles.cardDonateurs}>
          {
            // Mapping pour afficher le nombre de components demandé avec en passage de props les éléments nécessaires
            // à faire parvenir à l'enfant pour setter le state "results"
            Object.keys(results).map(function (item, i) {
              return (
                <Donations
                  handleChange={handleChange}
                  resultKey={i}
                  functionSetResults={FunctionSetResults}
                  FunctionSetAllDonatairesList={FunctionSetAllDonatairesList}
                  donataires={donataires}
                  simulateurid={props.simulateur.id}
                />
              );
            })
          }
        </div>
      </div>
    </div>
  );
}

export default Simulateur_Donations;

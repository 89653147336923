import React from "react";
import Pdf1 from "../../medias/avis/liquidation_CSI_dans_donation_demembree_bien_immobilier.pdf";
import Pdf5 from "../../medias/avis/pma_ou_amp.pdf";
import Pdf9 from "../../medias/avis/liquidation_participation_aux_acquets.pdf";
import AvisDExperts from "../../assets/images/2024_Avis_d_experts.png";
import "./informations.css";
import ReactGA from "react-ga4";

function Avis(props) {
  const handleClick = (pdfName) => {
    ReactGA.event({
      category: "Fiches Famille",
      action: "Clic sur élément",
      label: pdfName,
    });

    window.open(pdfName, "_blank");
  };

  const handleClickExisting = () => {
    ReactGA.event({
      category: "Avis d'Experts",
      action: "Clic sur bouton Découvrir",
      label: "Boutique en ligne",
    });

    window.open("https://editionslangloys.com/produit/avis-dexperts/", "_blank");
  };

  let newDate = new Date();
  let year = newDate.getFullYear();
  const currentDate = `${year}`;

  return (
    <div className={"container"}>
      <div className={"card"}>
        <h1 className={"cardTitle"}>Avis d'Experts</h1>

        <div className={"avis-headerCard"}>
          <div className={"avis-imageCard1"}>
            <div className={"avis-textContent1"}>
              À votre disposition en téléchargement, <br />3 Avis d'Experts parmi les plus plébicités par nos clients
            </div>
            <img className={"imageExperts"} src={AvisDExperts} alt="avis d'experts"></img>
          </div>

          <div className={"avis-imageCard2"}>
            <div className={"avis-textContent1"}>
              Les 13 Avis d'Experts de l'Édition {currentDate} sont disponibles sur notre boutique en ligne
            </div>
            <button className={"callToAction"} onClick={handleClickExisting}>
              Découvrir
            </button>
          </div>
        </div>

        <div className={"avisCard"}>
          <div className={"avis1 background1"} onClick={() => handleClick(Pdf1)}>
            <span className={"avis-textContent2"}>N°1</span>
            La liquidation de la CSI dans une donation démembrée d’un bien immobilier
          </div>

          <div className={"avis1 background2"} onClick={() => handleClick(Pdf5)}>
            <span className={"avis-textContent2"}>N°5</span>
            La Procréation Médicalement Assistée (PMA) ou Assistance Médicale à la Procréation (AMP) : quelle taxe pour
            quel acte ?
          </div>

          <div className={"avis1 background3"} onClick={() => handleClick(Pdf9)}>
            <span className={"avis-textContent2"}>N°9</span>
            Liquidation d’une participation aux acquêts : principes applicables à l’émolument d’acte
          </div>
        </div>
      </div>
    </div>
  );
}

export default Avis;

import React, { useEffect, useState } from "react";
import TextfieldNumber from "../textfields/textfield.number";
import TextfieldAbattement from "../textfields/textfield.abattement";
import { styles } from "../../stylesheets/simulateurs.css";
import SelectCommon from "../selects/select.common";
import ButtonYesno from "../buttons/button.yesno.js";
import { useDispatch } from "react-redux";
import { setDMTGObject } from "../../actions/dmtg.action";
import { ToolTipPerso } from "../selects/tooltip_perso";
import { Typography } from "@mui/material";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import moment from "moment";
import {useSnackbar} from "notistack";

const Donations_Mixtes = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // Ici props contient donc :
  // props.resultKey => key du tableau "results" nécessaire pour setter la bonne valeur
  // props.functionSetResults => pour lancer la fonction du main depuis le component
  // ----

  // States avec les différentes valeurs à stocker
  // Possibilité également de mettre tous les states (value1, value2....) dans un seul state sous format:
  //
  // state: {
  //      value1: {},
  //      value2: {},
  //      ...
  // }
  //
  // Dans ce cas penser à changer le callback du useEffect qui prend plus bas [value1, value2]
  // ---

  const [lienParente, setLien] = useState(0);
  //const [nomDonataire, setNomDonataire] = useState(0)
  const [loiTepa, setTepa] = useState(0);
  const [handicap, setHandicap] = useState(0);
  const [montantDonation, setMontantDonation] = useState(0);
  const [montantEspeces, setEspeces] = useState(0);
  const [abattementLegal, setAbattementLegal] = useState(0);
  const abattementTepa = "31 865 €";
  const [montantUtilise, setMontantUtilise] = useState(0);
  const [montantTepaUtilise, setMontantTepaUtilise] = useState(0);
  const [dateDonation, setDateDonation] = useState(new Date());
  const [donationsAnterieures, setDonationsAnterieures] = useState(0);
  const [donationsData, setDonationsData] = useState([{}]);
  let rappelFiscal;

  const handleChange = async (setter, value) => {
    if(setter === setDateDonation && donationsData.length > 0 && donationsData[0]['date']) {
      if(value < donationsData[0]['date']) {
        enqueueSnackbar("La date doit être postérieure à celle de la donation antèrieure", {
          variant: "error",
        });
        return;
      }
      rappelFiscal = new Date(value);
      rappelFiscal.setFullYear(rappelFiscal.getFullYear() - 15);
      if(donationsData[donationsData.length - 1]['date']) {
        if(donationsData[donationsData.length - 1]['date'] < parseInt(moment(rappelFiscal.toString()).utc(true).format("x"))) {
          enqueueSnackbar("Vous aurez une ou plusieurs donations antérieures en dehors du rappel fiscal, merci de vérifier les données renseignées", {
            variant: "error",
          });
          return;
        }
      } else {
        if(donationsData[donationsData.length - 2]['date'] < parseInt(moment(rappelFiscal.toString()).utc(true).format("x"))) {
          enqueueSnackbar("Vous aurez une ou plusieurs donations antérieures en dehors du rappel fiscal, merci de vérifier les données renseignées", {
            variant: "error",
          });
          return;
        }
      }
    }
    setter(value);
    setInRedux(setter, value);
  };

  const handleDonationChange = async (index, key, value) => {
    if(key === 'date') {
      rappelFiscal = new Date(dateDonation);
      rappelFiscal.setFullYear(rappelFiscal.getFullYear() - 15);
      if(index === 0) {
        if(value > dateDonation) {
          enqueueSnackbar("La date doit être antèrieure à celle de la nouvelle donation", {
            variant: "error",
          });
          return;
        }
      } else {
        if(value > donationsData[index - 1]['date']) {
          enqueueSnackbar("La date doit être antèrieure à celle de la donation précédente", {
            variant: "error",
          });
          return;
        }
      }
      if(value < parseInt(moment(rappelFiscal.toString()).utc(true).format("x"))) {
        enqueueSnackbar("La date est en dehors du rappel fiscal", {
          variant: "error",
        });
        return;
      }
    }
    const updatedDonations = [...donationsData];
    updatedDonations[index][key] = value;
    setInRedux(setDonationsData, updatedDonations);
  };

  const addDonation = () => {
    if (!donationsData[donationsData.length - 1]['date'] || !donationsData[donationsData.length - 1]['montant']) {
      enqueueSnackbar("Merci de remplir les informations de la donation antèrieure avant d'en rajouter une autre", {
        variant: "error",
      });
      return;
    }
    const updatedDonations = [...donationsData, {}];
    setDonationsData(updatedDonations);
  };

  const removeDonation = () => {
    if (donationsData.length > 1){
      const updatedDonations = [...donationsData];
      updatedDonations.pop();
      setDonationsData(updatedDonations);
      setInRedux(setDonationsData, updatedDonations);
    }
  };

  const texteAvecSautsDeLigne =
    "Abattement supplémentaire de 31 865€ sur les dons de somme d'argent, soumis aux conditions suivantes : \n - Le donateur doit, au jour de la transmission, être âgé de moins de 80ans ; \n - Le bénificiaire doit être majeur, c'est-à-dire avoir au moins 18 ans, au jour de la transmission (ou avoir fait l'objet d'une émancipation) ; \n - Les dons doivent être effectués en pleine propriété : aux enfants, petits-enfants ou arrière-petits-enfants; à défaut de descendance, aux neuveux et nièces; ou en cas de décès des neuveux et nièces, par représentation à des petits-neveux ou des petites nièces.";

  const lignes = texteAvecSautsDeLigne.split("\n");

  function setInRedux(setter, value) {
    let object = {
      simulateurid: props.simulateurid,
      inputs: {},
      donations_anterieures: donationsData.map(({ montant, date}) => ({
      montant: parseFloat(montant),
      date: Date.parse(date)
    }))
    };
    if (setter === setLien) {
      if (value === "1" || "3" || "7") {
        handleChange(setTepa, 0);
        handleChange(setMontantTepaUtilise, 0);
      }
      Object.assign(object["inputs"], {lien_parente_donation: parseInt(value) });
      Object.assign(object["inputs"], { tepa: parseInt(loiTepa) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_donation: parseInt(montantDonation) });
      Object.assign(object["inputs"], { montant_especes: parseInt(montantEspeces) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montantUtilise) });
      Object.assign(object["inputs"], { montant_tepa_utilise: parseInt(montantTepaUtilise) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object['inputs'], {date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x"))})
      }

    }

    if (setter === setTepa) {
      Object.assign(object["inputs"], { lien_parente_donation: parseInt(lienParente) });
      Object.assign(object["inputs"], { tepa: parseInt(value) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_donation: parseInt(montantDonation) });
      Object.assign(object["inputs"], { montant_especes: parseInt(montantEspeces) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montantUtilise) });
      Object.assign(object["inputs"], { montant_tepa_utilise: parseInt(montantTepaUtilise) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object['inputs'], {date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x"))})
      }
    }

    if (setter === setHandicap) {
      if (value === 1 && lienParente === "1") {
        handleChange(setLien, "1");
      }
      Object.assign(object["inputs"], { lien_parente_donation: parseInt(lienParente) });
      Object.assign(object["inputs"], { tepa: parseInt(loiTepa) });
      Object.assign(object["inputs"], { handicap: parseInt(value) });
      Object.assign(object["inputs"], { montant_donation: parseInt(montantDonation) });
      Object.assign(object["inputs"], { montant_especes: parseInt(montantEspeces) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montantUtilise) });
      Object.assign(object["inputs"], { montant_tepa_utilise: parseInt(montantTepaUtilise) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object['inputs'], {date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x"))})
      }
    }

    if (setter === setDateDonation) {
      Object.assign(object["inputs"], { lien_parente_donation: parseInt(lienParente) });
      Object.assign(object["inputs"], { tepa: parseInt(loiTepa) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_donation: parseInt(montantDonation) });
      Object.assign(object["inputs"], { montant_especes: parseInt(montantEspeces) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montantUtilise) });
      Object.assign(object["inputs"], { montant_tepa_utilise: parseInt(montantTepaUtilise) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (value !== undefined && dateDonation !== null) {
        Object.assign(object['inputs'], {date_nouvelle_donation: parseInt(moment(value.toString()).utc(true).format("x"))})
      }
    }

    if (setter === setMontantDonation) {
      Object.assign(object["inputs"], { lien_parente_donation: parseInt(lienParente) });
      Object.assign(object["inputs"], { tepa: parseInt(loiTepa) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_donation: parseInt(value) });
      Object.assign(object["inputs"], { montant_especes: parseInt(montantEspeces) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montantUtilise) });
      Object.assign(object["inputs"], { montant_tepa_utilise: parseInt(montantTepaUtilise) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object['inputs'], {date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x"))})
      }
    }

    if (setter === setEspeces) {
      Object.assign(object["inputs"], { lien_parente_donation: parseInt(lienParente) });
      Object.assign(object["inputs"], { tepa: parseInt(loiTepa) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_donation: parseInt(montantDonation) });
      Object.assign(object["inputs"], { montant_especes: parseInt(value) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montantUtilise) });
      Object.assign(object["inputs"], { montant_tepa_utilise: parseInt(montantTepaUtilise) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object['inputs'], {date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x"))})
      }
    }
    if (setter === setMontantUtilise) {
      Object.assign(object["inputs"], { lien_parente_donation: parseInt(lienParente) });
      Object.assign(object["inputs"], { tepa: parseInt(loiTepa) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_donation: parseInt(montantDonation) });
      Object.assign(object["inputs"], { montant_especes: parseInt(montantEspeces) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(value) });
      Object.assign(object["inputs"], { montant_tepa_utilise: parseInt(montantTepaUtilise) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object['inputs'], {date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x"))})
      }
    }
    if (setter === setMontantTepaUtilise) {
      Object.assign(object["inputs"], { lien_parente_donation: parseInt(lienParente) });
      Object.assign(object["inputs"], { tepa: parseInt(loiTepa) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_donation: parseInt(montantDonation) });
      Object.assign(object["inputs"], { montant_especes: parseInt(montantEspeces) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montantUtilise) });
      Object.assign(object["inputs"], { montant_tepa_utilise: parseInt(value) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = donationsData;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object['inputs'], {date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x"))})
      }
    }

    if (setter === setDonationsAnterieures) {
      if (value === 0) {
        setDonationsData([{}])
        object.donations_anterieures = [{}];
      } else {
        object.donations_anterieures = donationsData;
      }
      Object.assign(object["inputs"], { lien_parente_donation: parseInt(lienParente) });
      Object.assign(object["inputs"], { tepa: parseInt(loiTepa) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_donation: parseInt(montantDonation) });
      Object.assign(object["inputs"], { montant_especes: parseInt(montantEspeces) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montantUtilise) });
      Object.assign(object["inputs"], { montant_tepa_utilise: parseInt(montantTepaUtilise) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object['inputs'], {date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x"))})
      }
    }

    if (setter === setDonationsData) {
      Object.assign(object["inputs"], { lien_parente_donation: parseInt(lienParente) });
      Object.assign(object["inputs"], { tepa: parseInt(loiTepa) });
      Object.assign(object["inputs"], { handicap: parseInt(handicap) });
      Object.assign(object["inputs"], { montant_donation: parseInt(montantDonation) });
      Object.assign(object["inputs"], { montant_especes: parseInt(montantEspeces) });
      Object.assign(object["inputs"], { montant_utilise: parseInt(montantUtilise) });
      Object.assign(object["inputs"], { montant_tepa_utilise: parseInt(montantTepaUtilise) });
      Object.assign(object["inputs"], { donateur: parseInt(props.donateur) });
      Object.assign(object["inputs"], { donataire: parseInt(props.soloResultKey + 1) });
      object.donations_anterieures = value;
      if (dateDonation !== undefined && dateDonation !== null) {
        Object.assign(object['inputs'], {date_nouvelle_donation: parseInt(moment(dateDonation.toString()).utc(true).format("x"))})
      }
    }

    /*           if (setter === setNomDonataire) {
                       props.functionSetDonatairesList(props.soloResultKey, {"nom": value})
                   }*/

    props.functionSetSoloResults(props.soloResultKey, object);
    dispatch(setDMTGObject(object));
  }

  const lienDonation = [
    {
      id: 1,
      name: "Entre époux ou entre partenaires pacsés",
    },
    {
      id: 2,
      name: "En ligne directe - Ascendants ou enfants",
    },
    {
      id: 3,
      name: "Entre frères et sœurs",
    },
    {
      id: 4,
      name: "Aux neveux et nièces",
    },
    {
      id: 5,
      name: "Aux petits enfants",
    },
    {
      id: 6,
      name: "Aux arrières petits enfants",
    },
    {
      id: 7,
      name: "Entre parents au-dela du 4ème degré et non parents",
    },
  ];

  // Hook appelé à la génération de la page car aucune restriction n'est imposé aux fields value1 et value2.
  // Ce hook captera chaque modification de field et appelera la fonction du App.js "FunctionSetResults"
  // pour set la key correspondante à l'appel de component dans le state "results".

  useEffect(() => {
    if (lienParente === "1" && handicap === 1) {
      setAbattementLegal("240 049");
    } else if (lienParente === "1") {
      setAbattementLegal("80 724");
    } else if (lienParente === "2" && handicap === 1) {
      setAbattementLegal("259 325");
    } else if (lienParente === "2") {
      setAbattementLegal("100 000");
    } else if (lienParente === "3" && handicap === 1) {
      setAbattementLegal("175 257");
    } else if (lienParente === "3") {
      setAbattementLegal("15 932");
    } else if (lienParente === "4" && handicap === 1) {
      setAbattementLegal("167 286");
    } else if (lienParente === "4") {
      setAbattementLegal("7 967");
    } else if (lienParente === "5" && handicap === 1) {
      setAbattementLegal("191 190");
    } else if (lienParente === "5") {
      setAbattementLegal("31 865");
    } else if (lienParente === "6" && handicap === 1) {
      setAbattementLegal("164 635");
    } else if (lienParente === "6") {
      setAbattementLegal("5 310");
    } else if (lienParente === "7" && handicap === 1) {
      setAbattementLegal("159 325");
    } else if (lienParente === "7") {
      setAbattementLegal("0");
    }
  }, [lienParente, handicap]);

  return (
    <div style={styles.cardFlexContainer}>
      {/*<div>
                    <div style={styles.cardRow}>
                        <div style={styles.cardElemVerticalyCenter}>
                            Indiquer le nom du donataire
                        </div>
                        <TextfieldNumber
                            handleChange={handleChange}
                            type={"text"}
                            placeholder={"Indiquer le nom"}
                            setter={setNomDonataire}
                        />
                    </div>
                </div>*/}

      <div style={styles.cardTitleDonataire}>Donataire {props.soloResultKey + 1}</div>

      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>Quel est leur lien de parenté ?</div>
        <SelectCommon
          items={lienDonation}
          defaultValue={"1"}
          nameField={"name"}
          optionValue={"id"}
          handleChange={handleChange}
          setter={setLien}
        />
      </div>

      {parseInt(lienParente) === 2 ||
      parseInt(lienParente) === 4 ||
      parseInt(lienParente) === 5 ||
      parseInt(lienParente) === 6 ? (
        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Souhaitez-vous ajouter l'abattement spécifique loi TEPA ?
            <ToolTipPerso
              title={
                <Typography style={{ fontSize: 14 }}>
                  {lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {ligne}
                    </React.Fragment>
                  ))}
                </Typography>
              }
            />{" "}
          </div>
          <ButtonYesno handleChange={handleChange} setter={setTepa} defaultValue={0} />
        </div>
      ) : (
        <></>
      )}

      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>
          Le bénéficiaire est-il en situation de handicap ?
          <ToolTipPerso title={"En cas d’invalidité permanente, abattement de 159 325 € cumulable."} />
        </div>
        <ButtonYesno handleChange={handleChange} setter={setHandicap} defaultValue={0} />
      </div>

      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>
          Indiquer la date de la donation
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              value={dateDonation}
              onChange={((val) => {
                handleChange(setDateDonation, val)
              })}
              renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>

      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>
          Montant de la donation lui revenant
          <ToolTipPerso
            title={
              "S'il s'agit d'une donation avec réserve d'usufruit, merci d'indiquer la part donnée en nue-propriété."
            }
          />
        </div>
        <TextfieldNumber
          handleChange={handleChange}
          type={"number"}
          placeholder={"Indiquer le montant"}
          setter={setMontantDonation}
        />
      </div>

      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>Dont montant des espèces</div>
        <TextfieldNumber
          handleChange={handleChange}
          type={"number"}
          placeholder={"Indiquer le montant"}
          setter={setEspeces}
        />
      </div>

      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>Abattement légal</div>
        <TextfieldAbattement type={"number"} placeholder={abattementLegal + " €"} />
      </div>

      {parseInt(loiTepa) === 1 ? (
        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>Abattement TEPA</div>
          <TextfieldAbattement type={"number"} placeholder={abattementTepa} />
        </div>
      ) : (
        <></>
      )}

      {/*DONATIONS ANTERIEURES*/}
      <div style={styles.cardRow}>
        <div style={styles.cardElemVerticalyCenter}>
          Le bénéficiaire a-t-il déjà reçu une donation ?
          <ToolTipPerso title={"De la part du même donateur et dans le délai de rappel fiscal des donations antérieures qui est de 15ans."} />
        </div>
        <ButtonYesno handleChange={handleChange} setter={setDonationsAnterieures} defaultValue={0} />
      </div>

      {donationsAnterieures ? (
        <div>
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>
              Part de l’abattement légal déjà utilisé
              <ToolTipPerso title={"Dans le délai de rappel fiscal des donations antérieures qui est de 15ans."} />
            </div>
            <TextfieldNumber
                handleChange={handleChange}
                type={"number"}
                placeholder={"Indiquer le montant"}
                setter={setMontantUtilise}
            />
          </div>

          {parseInt(loiTepa) === 1 ? (
              <div style={styles.cardRow}>
                <div style={styles.cardElemVerticalyCenter}>Part de l’abattement TEPA déjà utilisé</div>
                <TextfieldNumber
                    handleChange={handleChange}
                    type={"number"}
                    placeholder={"Indiquer le montant"}
                    setter={setMontantTepaUtilise}
                />
              </div>
          ) : (
              <></>
          )}

          {donationsData.map((donation, index) => (
              <div key={index}>

                <div style={styles.cardRow}>
                  <div style={styles.cardElemVerticalyCenter}>Indiquer la date de la donation antérieure {index +1}</div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        value={donation.date}
                        onChange={(e) => {
                          void handleDonationChange(index, 'date', parseInt(moment(e.toString()).utc(true).format("x")))
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div style={styles.cardRow}>
                  <div style={styles.cardElemVerticalyCenter}>Montant de la donation antérieure {index +1}</div>
                  <input
                      className={"input_field"}
                      type={"number"}
                      placeholder={"Indiquer le montant"}
                      onChange={(e) => {
                        void handleDonationChange(index, 'montant', parseFloat(e.target.value))
                      }}
                  />
                </div>
              </div>
          ))}
          <div>
            <div style={styles.buttonsArea}>
              <button style={styles.removeButton} type="button" onClick={() => removeDonation()}>Supprimer donation</button>
              <button style={styles.addButton} type="button" onClick={addDonation}>Ajouter donation</button>
            </div>
          </div>
        </div>
      ) : (
          <></>
      )}

    </div>
  );
};
export default Donations_Mixtes;

import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import ButtonText from "../../../components/buttons/button.text";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import { ToolTipPerso } from "../../../components/selects/tooltip_perso";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";

// Prêts aux particuliers garantis par une hypothèque conventionnelle et prêts viagers garantis par une hypothèque conventionnelle
// id simu 87

function Simulateur_A1_9(props, simulateur, isParent, departements) {
  const dispatch = useDispatch();

  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [montant_pret, setMontant] = useState(0);
  const [departement, setDepartement] = useState(0);
  const [pret, setPret] = useState(0);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
      };

      if (setter === setMontant) {
        Object.assign(object["inputs"], { montant_pret: parseInt(value) });
        Object.assign(object["inputs"], { pret_realise: parseInt(pret) });
        object.departementid = parseInt(departement);
      }

      if (setter === setPret) {
        Object.assign(object["inputs"], { montant_pret: parseInt(montant_pret) });
        Object.assign(object["inputs"], { pret_realise: parseInt(value) });
        object.departementid = parseInt(departement);
      }

      if (setter === setDepartement) {
        Object.assign(object["inputs"], { montant_pret: parseInt(montant_pret) });
        Object.assign(object["inputs"], { pret_realise: parseInt(pret) });
        object.departementid = parseInt(value);
      }

      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>Acte tarifé au titre du Tableau 5, n° 137 (Art A444-143)</p>

        {props?.isParent === true && props.departements !== "" ? (
          <div style={styles.cardRow}>
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            L'acte de prêt est réalisé :
            <ToolTipPerso
              title={
                "Pour rappel : par « acte séparé » signifie que la convention est la disposition principale de l'acte (un prêt) et « dans le même acte » correspond à la situation où des conventions juridiques distinctes et indépendantes y sont contenues (par exemple une vente et un prêt)."
              }
            />
          </div>
          <ButtonText handleChange={handleChange} setter={setPret} defaultValue={0} />
        </div>

        <div style={styles.cardRow}>
          <div style={styles.cardElemVerticalyCenter}>
            Indiquer le montant du prêt :
            <ToolTipPerso
              title={"Les accessoires sont compris et évalués au bordereau à concurrence de 20% du capital emprunté."}
            />
          </div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setMontant}
          />
        </div>
      </div>
    </div>
  );
}

export default Simulateur_A1_9;

import React, { useState } from 'react';
import TextfieldNumber from "../textfields/textfield.number";
import TextfieldLong from "../textfields/textfield.long";
import ButtonTextSolo from "../buttons/button_text_solo";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { useSnackbar } from "notistack";
import useIsMountedRef from "../../utils/mountedRef";
import Colors from "../../useclass/colors";
import TextfieldEmail from '../textfields/textfield.email';

const Mail = (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [mailingObject, setMailingObject] = useState("");
    const [mailingBody, setMailingBody] = useState("");
    const handleChange = async (setter, value) => {
        setter(value)
    };

    function sendMailing() {
        let user = JSON.parse(localStorage.getItem("user"))
        let object = {
            "mail_client": user.email,
            "subject": mailingObject,
            "body": mailingBody
        }
        var config = {
            method: 'post',
            url: `${API_BASE_URL}/mail`,
            data: object,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': JSON.parse(localStorage.getItem("user"))?.jwt,
            },
        };
        axios(config)
            .then((response) => {
                if (response.status === 200 && response.data != null) {
                    if (response.data !== null) {
                        enqueueSnackbar('Message envoyé !', {
                            variant: 'success'
                        });
                        props.setOpen(false)
                    } else
                        enqueueSnackbar('Une erreur est survenue', {
                            variant: 'error'
                        });
                }
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data != null) {
                  if (error.response.data.error == "Other_user_connected" ) {
                    localStorage.removeItem("user")
                    localStorage.setItem("other_user_connected", "other_user_connected")
                    window.location = "login"
                  }
                }
              
              })
    }

    let styles = {}

    if (props.floatItem)
        styles = {
            card: {
                position: "fixed",
                right: 30,
                bottom: 50,
                backgroundColor: Colors.white,
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                //width: "95%",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: 4,
                minWidth: 400,
                width: "30%",
                zIndex:4,
            },
            ctnCrossSVG: {
                //marginTop: 15,
                //marginRight: 15,
                cursor: "pointer",
                position: 'absolute',
                top: 30,
                right: 10,
                mixBlendMode: "color-dodge"
            },
            starCard: {
                marginTop: 16,
                marginBottom: 16,
                paddingLeft: 20,
                paddingRight: 20,
            },

            cardHeader: {
                backgroundColor: Colors.lightGrey,
                borderRadius: "4px 4px 0px 0px",
                width: "100%",
                height: "auto",
                display: "grid",
                gridTemplateColumns: "1fr 3fr",
                paddingTop: 5,
                paddingBottom: 5
            },
            cardHeaderBlue: {
                backgroundColor: Colors.primaryBlue,
                borderRadius: "4px 4px 0px 0px",
                width: "100%",
                height: "auto",
                display: "grid",
                gridTemplateColumns: "1fr 3fr",
                color: "white",
                paddingTop: 5,
                paddingBottom: 5
            },
            logo: {
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "auto",
                marginLeft: 15,
            },
            list: {},
            listElem: {
                lineHeight: 1.8,
                cursor: "pointer"
            },
            listElemStar: {
                lineHeight: 1.8,
                listStyleType: "none",
                display: "flex",
                flexDirection: "row"
            },
            liStar: {
                marginRight: 10,
                marginTop: 3
            },
            cardBody: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            cardTitle: {
                fontWeight: "normal",
                color: Colors.titleBlue
            },
            cardTitleWhite: {
                fontWeight: "normal",
                color: "white"
            },
            cardBodyMailing: {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "15px",
                paddingBottom: 20,
                paddingTop: 15
            },
            cardBodyMailingItem: {
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%'
            },
            textArea: {
                width: "100%",
                minHeight: 50,
            }, cardBodyMailingTitle: {
                textAlign: "center",
                marginRight: "5%",
            },
            logoMail: {
                marginTop: 17,
                marginBottom: "auto",
                marginRight: "auto",
                marginLeft: 5,
            },

        };
    else
        styles = {
            card: {
                marginTop: 10,
                marginBottom: 30,
                backgroundColor: Colors.white,
                boxShadow: "2px 2px 1px rgba(0,0,0, 0.2)",
                //width: "95%",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: 4,
                minWidth: 400,
                width: "30%",
            },
            ctnCrossSVG: {
                paddingTop: 10,
                display: "none"
            },
            starCard: {
                marginTop: 16,
                marginBottom: 16,
                paddingLeft: 20,
                paddingRight: 20,
            },

            cardHeader: {
                backgroundColor: Colors.lightGrey,
                borderRadius: "4px 4px 0px 0px",
                width: "100%",
                height: "auto",
                display: "grid",
                gridTemplateColumns: "1fr 3fr",
                paddingTop: 5,
                paddingBottom: 5
            },
            cardHeaderBlue: {
                backgroundColor: Colors.primaryBlue,
                borderRadius: "4px 4px 0px 0px",
                width: "100%",
                height: "auto",
                display: "grid",
                gridTemplateColumns: "1fr 3fr",
                color: "white",
                paddingTop: 5,
                paddingBottom: 5
            },
            logo: {
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "auto",
                marginLeft: 15,
            },
            logoMail: {
                marginTop: "15px",
                marginBottom: "auto",
                marginRight: "auto",
                marginLeft: 15,
            },
            list: {},
            listElem: {
                lineHeight: 1.8,
                cursor: "pointer"
            },
            listElemStar: {
                lineHeight: 1.8,
                listStyleType: "none",
                display: "flex",
                flexDirection: "row"
            },
            liStar: {
                marginRight: 10,
                marginTop: 3
            },
            cardBody: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            cardTitle: {
                fontWeight: "normal",
                color: Colors.titleBlue
            },
            cardTitleWhite: {
                fontWeight: "normal",
                color: "white"
            },
            cardBodyMailing: {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "15px",
                paddingBottom: 20,
                paddingTop: 15
            }, textArea: {

                width: "100%",
                minHeight: 50
            }, cardBodyMailingTitle: {
                textAlign: "center"
            }

        };

    if (props.state || !props.floatItem)
        return (
            <div style={styles.card}>
                <div style={styles.cardHeader}>
                    <div style={styles.logoMail}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="58.52" height="43.89"
                            viewBox="0 0 50.351 54.121">
                            <path id="Icon_awesome-calendar-check" data-name="Icon awesome-calendar-check"
                                d="m5.49,0C2.46,0,0,2.46,0,5.49c0,1.73.81,3.35,2.19,4.39l24.87,18.65c1.3.97,3.09.97,4.39,0l24.87-18.65c1.38-1.04,2.19-2.66,2.19-4.39,0-3.03-2.46-5.49-5.49-5.49,0,0-47.55,0-47.55,0ZM0,12.8v23.77c0,4.03,3.28,7.32,7.32,7.32h43.89c4.03,0,7.32-3.28,7.32-7.32V12.8l-24.87,18.65c-2.61,1.95-6.17,1.95-8.78,0L0,12.8Z"
                                fill="#5E91B6" />
                        </svg>
                    </div>
                    <h2 style={styles.cardTitle}>
                        Vos remarques
                    </h2>
                </div>

                <div style={styles.ctnCrossSVG}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.142" height="21.142"
                        viewBox="0 0 21.142 21.142"
                        onClick={(event) => props.setOpen(false)}
                    >
                        <g id="Groupe_12" data-name="Groupe 12" transform="translate(1.768 1.768)" >
                            <line id="Ligne_1" data-name="Ligne 1" x2="17.607" y2="17.607" fill="none"
                                stroke="#dce0e3" strokeWidth="5" />
                            <line id="Ligne_2" data-name="Ligne 2" x1="17.607" y2="17.607" fill="none"
                                stroke="#dce0e3" strokeWidth="5" />
                        </g>
                    </svg>
                </div>

                <div style={styles.cardBody}>
                    <form onSubmit={((e) => {
                        e.preventDefault()
                    })}>
                        <div style={styles.cardBodyMailing}>
                            <div style={styles.cardBodyMailingTitle}>
                            </div>
                            <div style={styles.cardBodyMailingItem}>
                                <TextfieldEmail
                                    handleChange={handleChange}
                                    type={"text"}
                                    defaultValue={mailingObject}
                                    placeholder={"Objet"}
                                    setter={setMailingObject}
                                />
                            </div>
                            <div style={styles.cardBodyMailingItem}>
                                <TextfieldLong
                                    placeholder={"Faites-nous part de vos remarques"}
                                    setter={setMailingBody}
                                    defaultValue={mailingBody}
                                    handleChange={handleChange}
                                />

                            </div>
                            <div style={styles.cardBodyMailingItem}>
                                <ButtonTextSolo
                                    text={"Envoyer"}
                                    setter={sendMailing} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    else return <></>
};

export default Mail;
